angular.module(
    'ShaasApp.controllers.WifiController',
    [
        'ShaasApp.services.ConnectorService',
        'ShaasApp.services.UtilsService'
    ]
)

.controller( 'WifiController', [
    'utils',
    'store',
    'config',
    '$translate',
    '$location',
    '$rootScope',
    '$mdToast',
    '$scope',
    function (utils, store, config, $translate, $location, $rootScope, $mdToast, $scope) {

        var wifi = this;
        wifi.uuid = $rootScope.uuid();
        wifi.password_uncompleted = true;
        wifi.loading_wifi = false;
        wifi.changed_ok = false;
        wifi.more = false;

        wifi.get_data = function() {

            connector = $rootScope.connector;

            connector.wifi_get().then(function(response) {
                wifi.networks = response.data.wifi;

                if (wifi.networks.length > 1) {
                    wifi.more = true;
                }

                first_wifi = wifi.networks[0];
                wifi.ssid = first_wifi.ssid;
                wifi.password = first_wifi.key;
                wifi.encrypted = first_wifi.encryption;
                wifi.index = first_wifi.index;
                if (wifi.encrypted != "none") {
                    wifi.password_activated = true;
                } else {
                    wifi.password_activated = false;
                }
            }, function(error) {

            });
        }

        wifi.set_data = function(data) {
            wifi.ssid = data.ssid;
            wifi.password = data.key;
            wifi.encrypted = data.encryption;
            wifi.index = data.index;
            if (wifi.encrypted != "none") {
                wifi.password_activated = true;
            } else {
                wifi.password_activated = false;
            }
        }

        wifi.update = function(index, changed_ssid, changed_encrypted, changed_password) {
            connector = $rootScope.connector;

            if ((changed_ssid == undefined || changed_ssid == 0) || (changed_password == undefined || changed_password == 0)) {
                wifi.loading_wifi = false;
                return;
            }
            if (changed_ssid || changed_password || changed_encrypted) {
                wifi.loading_wifi = true;
            }

            if (changed_encrypted == false) {
                changed_encrypted = "none";
                changed_password = null;
            } else {
                changed_encrypted = "psk-mixed";
            }

            wifiToUpdate = {
                "ssid": changed_ssid,
                "encryption": changed_encrypted,
                "key": changed_password
            };

            connector.wifi_update(wifiToUpdate, index).then(function(response) {
                if (response.status = 204) {
                    $mdToast.show(
                        $mdToast.simple()
                            .textContent($translate.instant('WIFI.UPDATE_OK'))
                            .position($scope.getToastPosition())
                            .hideDelay(4000)
                    );
                    for (var i=0; i < wifi.networks.length; i++) {
                      if(wifi.networks[i] != null){
                        if(wifi.networks[i].index == index){
                          wifi.networks[i].ssid = changed_ssid
                          wifi.networks[i].key = changed_password
                          wifi.networks[i].encryption = changed_encrypted
                        }
                      }
                    }
                    router.name = wifi.networks;
                } else {
                    swal(
                    $translate.instant('WIFI.ERROR_UPDATE'),
                    $translate.instant('WIFI.TRY_AGAIN'),
                    'error'
                    );
                }
                wifi.loading_wifi = false;
                wifi.changed_ok = false;
            });
        };
}]);
