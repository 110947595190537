angular.module('ShaasApp.controllers.ChartsController', [
        'ShaasApp.services.ConnectorService',
        'ShaasApp.services.UtilsService',
        'chart.js'
])

.controller('ChartsController', [
    'utils',
    '$translate',
    '$routeParams',
    '$interval',
    '$scope',
    '$rootScope',
    'BootService',
    function(utils, $translate, $routeParams, $interval, $scope, $rootScope, BootService) {

        var charts = this;
        var labels = ['', '', '', '', '', '', ''];
        var series = ['Up', 'Down'];


        charts.init = function() {
            charts.series = series;
            charts.labels = labels;
            charts.colours = ['#4E7FFF', '#8FF895'];

            charts.options = {
                'scaleFontColor': '#949494'
            }

            charts.data = [
                [0,0,0,0,0,0],
                [0,0,0,0,0,0]
            ];

            promise = $interval(function () {
                charts.get_data($routeParams.id)
            }, 5000);

            stop = function() {
                $interval.cancel(promise);
            };

            $scope.$on('$destroy', function() {
              stop();
            });
        };

        charts.get_data = function(class_id) {
            connector = $rootScope.connector;
            if (typeof class_id == 'undefined') {
                return;
            }
            connector.classes_get(class_id).then(function(response) {
                if (response.status == 200) {
                    charts.device_name = response.data.classes._meta.description || $translate.instant('GENERAL.DEVICE');
                    // TODO update labels with time.
                    // if (labels.length == 7) { labels.shift() }
                    // labels.push(new Date().toTimeString().split(" ")[0])
                    angular.forEach(charts.data, function(value, key) {
                        if (value.length == 7) { value.shift() }

                        if (key == 0) {
                            up_bandwidth = utils.bitsToAny(response.data.classes.up.rate, 'Kb').bandwidth
                            value.push(((up_bandwidth == null) ? 0 : up_bandwidth));
                            charts.up = utils.bitsToAny(response.data.classes.up.rate);
                            if (charts.up === "0") {
                                charts.up = {bandwidth:"0.0", units:"Kb"};
                            }
                        }

                        if (key == 1) {
                            down_bandwidth = utils.bitsToAny(response.data.classes.down.rate, 'Kb').bandwidth
                            value.push(((down_bandwidth == null) ? 0 : down_bandwidth));
                            charts.down = utils.bitsToAny(response.data.classes.down.rate);
                            if (charts.down === "0") {
                                charts.down = {bandwidth:"0.0", units:"Kb"};
                            }
                        }


                        // if (classes.device.bandwidth.up == undefined) {
                        //     classes.device.bandwidth.up = "0.0 kb";
                        // }

                    });

                } else {
                    swal(
                        $translate.instant('CLASSES.ERROR'),
                        $translate.instant('CLASSES.MODIFIED_WRONG'),
                        'error'
                    );
                }
            });
        };
}]);