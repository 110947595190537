angular.module('ShaasApp.controllers.resellers.ResellersController', [
    'ngMaterial'
])

.controller('ResellersController', [
    '$scope',
    '$mdToast',
    '$document',
    '$translate',
    '$mdDialog',
    '$mdMedia',
    'store',
    'config',
    'CloudConnector',
    'BootService',
    '$rootScope',
    'ConnectorRest',
    'ConnectorNoRest',
    function($scope, $mdToast, $document, $translate, $mdDialog, $mdMedia, store, config, CloudConnector, BootService, $rootScope, ConnectorRest, ConnectorNoRest) {

        var reseller = this;

        reseller.refresh = function() {
            reseller.controllers_get();
            reseller.reseller_get();
        }

        reseller.toggleController = function() {
                $scope.valor = $scope.valor === false ? true: false;
        }

        reseller.reseller_get = function() {
            token = store.get('reseller');

            CloudConnector.reseller_get(token).then(function(response) {
                if (response.status == 500) {
                    reseller.alertGeneralError();
                }

                reseller.quota = response.data.quota;
                reseller.first_name = response.data.first_name;
            }, function() {
                reseller.alertGeneralError();
            });
        }

        reseller.controllers_get = function() {

            token = store.get('reseller');

            if (token != undefined) {
                CloudConnector.controllers_get(token).then(function(response) {
                    if (response.status == 500) {
                        reseller.alertGeneralError();
                    }

                    reseller.list_controllers = response.data;
                    reseller.total = reseller.list_controllers.length;
                }, function() {
                    if (reseller.total == undefined) {
                        reseller.total = 0;    
                    }
                });
            }

        }

        reseller.controllers_registrations = function() {

            token = store.get('reseller');

            if (reseller.quota == 0) {
                var confirm = $mdDialog.confirm()
                    .title($translate.instant('REGISTER.QUOTA_CERO'))
                    .textContent($translate.instant('REGISTER.QUOTA_CERO_INFO'))
                    .ariaLabel('Quota zero')
                    .ok($translate.instant('REGISTER.QUOTA_CERO_CONTACT'))
                    .cancel($translate.instant('REGISTER.QUOTA_CERO_CANCEL'));

                $mdDialog.show(confirm).then(function() {
                    window.open("http://www.itcx.io/contact", "_blank");
                }, function() {
                    return;
                });
                return;
            }

            router = $rootScope.uuidRemote();
            if (router) {
                if (store.get(router).version == '15') {
                    $rootScope.connector = ConnectorNoRest;
                } else if (store.get(router).version == '14') {
                    $rootScope.connector = ConnectorRest;
                }
            }
            connector = $rootScope.connector;
            connector.credentials().then(function(response) {
                if (response.status == 200) {
                    router = response.data;
                    data = {
                        "jwt": response.data.jwt_console,
                        "uuid": response.data.uuid,
                        "ssh_pbk": response.data.ssh_pub
                    }

                    var confirm = $mdDialog.confirm()
                        .title($translate.instant('REGISTER.UP'))
                        .textContent($translate.instant('REGISTER.UP_INFO'))
                        .ariaLabel('Lucky day')
                        .ok($translate.instant('REGISTER.UP_YES'))
                        .cancel($translate.instant('REGISTER.UP_CANCEL'));

                    $mdDialog.show(confirm).then(function() {

                        CloudConnector.create_controller(token, data).then(function(response) {
                            if (response.status != 201) {
                                reseller.alertUpError();
                            }
                            body = {'uuid': router.uuid}
                            CloudConnector.plugins(config.plugins.tunnel, body).then(function(response) {
                                connector.plugins(response.data);
                                $mdToast.show(
                                    $mdToast.simple()
                                        .textContent($translate.instant('REGISTER.UP_OK'))
                                        .position($scope.getToastPosition())
                                        .hideDelay(4000)
                                );
                                reseller.controllers_get()
                                reseller.reseller_get();
                            }, function() {
                                reseller.alertUpError();
                            });
                        }, function() {
                            reseller.alertUpError();
                        });

                    }, function() {
                        return;
                    });

                } else {
                    reseller.alertRegisterErrorConnection();
                }
            }, function(response) {
                if (response.status == 401) {
                    reseller.alertLoginShaasError();
                }
                reseller.alertRegisterErrorConnection();
            });
        }

        reseller.alertRegisterErrorConnection = function(ev) {
            $mdDialog.show(
                $mdDialog.alert()
                    .parent(angular.element(document.querySelector('#popupContainer')))
                    .clickOutsideToClose(true)
                    .title ($translate.instant('REGISTER.ERROR_CONNECTION'))
                    .textContent ($translate.instant('REGISTER.ERROR_CONNECTION_INFO'))
                    .ariaLabel('Alert Dialog')
                    .ok ($translate.instant('REGISTER.UP_ERROR_OK'))
            );
            return;
        };

        reseller.alertLoginShaasError = function(ev) {
            $mdDialog.show(
                $mdDialog.alert()
                    .parent(angular.element(document.querySelector('#popupContainer')))
                    .clickOutsideToClose(true)
                    .title ($translate.instant('REGISTER.LOGIN_SHAAS_ERROR'))
                    .textContent ($translate.instant('REGISTER.LOGIN_SHAAS_ERROR_INFO'))
                    .ariaLabel('Alert Dialog')
                    .ok ($translate.instant('REGISTER.UP_ERROR_OK'))
            );
            return;
        };

        reseller.alertGeneralError = function(ev) {
            $mdDialog.show(
                $mdDialog.alert()
                    .parent(angular.element(document.querySelector('#popupContainer')))
                    .clickOutsideToClose(true)
                    .title ($translate.instant('RESELLER.ERROR'))
                    .textContent ($translate.instant('RESELLER.GENERAL_ERROR'))
                    .ariaLabel('Alert Dialog')
                    .ok ($translate.instant('REGISTER.UP_ERROR_OK'))
            );
            return;
        };

        reseller.alertUpError = function(ev) {
            $mdDialog.show(
                $mdDialog.alert()
                    .parent(angular.element(document.querySelector('#popupContainer')))
                    .clickOutsideToClose(true)
                    .title ($translate.instant('REGISTER.UP_ERROR'))
                    .textContent ($translate.instant('REGISTER.UP_ERROR_INFO'))
                    .ariaLabel('Alert Dialog')
                    .ok ($translate.instant('REGISTER.UP_ERROR_OK'))
                    .targetEvent(ev)
            );
        };

    }]);