angular.module('ShaasApp.controllers.SetupController', [
        'ShaasApp.services.ConnectorService',
        'ShaasApp.services.UtilsService',
        'angular-svg-round-progress'
])

.controller('SetupController', [
    '$rootScope',
    'roundProgressService',
    'utils',
    '$translate',
    '$window',
    'BootService',
    function($rootScope, roundProgressService, utils, $translate, $window, BootService) {

        var setup = this;
        setup.current =        0;
        setup.loading =        false;
        setup.max =            10;
        setup.offset =         0;
        setup.timerCurrent =   0;
        setup.uploadCurrent =  0;
        setup.stroke =         15;
        setup.radius =         125;
        setup.isSemi =         true;
        setup.rounded =        false;
        setup.responsive =     true;
        setup.clockwise =      true;
        setup.currentColor =   '#6A6CBB';
        setup.bgColor =        '#eaeaea';
        setup.duration =       1800;
        setup.currentAnimation = 'easeOutCubic';
        setup.animationDelay = 0;

        __construct = function() {
            connector = $rootScope.connector;
            setup.units = 'Mb';
            $rootScope.isDisabled = false;

            if (angular.isUndefined($rootScope.data_general)) {
                connector.status().then(function(response) {
                    if (response.status != 200) {
                        swal(
                        $translate.instant('HOME.ERROR'),
                        $translate.instant('HOME.MODIFIED_WRONG'),
                        'error'
                        );
                    }
                    data = response.data.status.bw_total;
                    bw = utils.bitsToAny(data);
                    if (bw != 0) {
                        setup.current = Number(bw.bandwidth);
                        setup.max = setup.current*2;
                        setup.units = bw.units;
                    }
                });
            } else {
                data = $rootScope.data_general.bw_total;
                bw = utils.bitsToAny(data);
                if (bw != 0) {
                    setup.current = Number(bw.bandwidth);
                    setup.max = setup.current*2;
                    setup.units = bw.units;
                }
            }
        }
        __construct();

        setup.increment = function(amount) {
            if (setup.units == 'Kb') {
                if (amount == undefined || amount == 10) {
                    if (setup.current == 900) {
                        setup.units = 'Mb';
                        setup.current = 1;
                    } else {
                        if (setup.current == 1000) {
                            setup.units = 'Mb';
                            setup.current = 2;
                        } else {
                            setup.current += (100);
                            if (setup.current > setup.max) {
                                setup.max = setup.current;
                            };
                        }
                    }
                } else {
                    setup.current = 300;
                }
            } else if (setup.units == 'Mb'){
                if (setup.current < 300) {
                    if (amount == undefined || amount == 10 && setup.current < 290) {
                        if (setup.current >= 5) {
                            setup.current += (amount || 1);
                        } else {
                            setup.current += (amount || .5);
                        }
                        if (setup.current > setup.max) {
                            setup.max = setup.current;
                        };
                    } else {
                        setup.current = 300;
                    }
                } else {
                    setup.current = 300;
                }
            }
        };

        setup.decrement = function(amount) {
            if (setup.units == 'Kb') {
                if (setup.current > 1 && amount == undefined) {
                    setup.current -= 100;
                } else if (setup.current > 100) {
                    setup.current -= (amount + 90 || 1);
                    setup.max = setup.current + 10;
                } else {
                    setup.current = 0;
                }
            } else if (setup.units == 'Mb') {
                if (setup.current > 1 && amount == undefined) {
                    if (setup.current >= 6) {
                        setup.current -= (amount || 1);
                    } else {
                        setup.current -= (amount || .5);
                    }
                } else if (setup.current > 10) {
                    setup.current -= (amount || 1);
                    setup.max = setup.current + 10;
                } else {
                    setup.units = 'Kb';
                    setup.current = 900;
                }
            }
        };

        setup.init = function() {
            connector = $rootScope.connector;
            bandwidth = utils.anyToBits(setup.current, setup.units);
            setup.loading = true;
            connector.setup(bandwidth).then(function(response) {
                if (response.status != 201) {
                    swal(
                        $translate.instant('SETUP.WRONG'),
                        $translate.instant ('SETUP.WRONG_MSG'),
                        'error'
                    );
                    setup.loading = false;
                } else if (response.data.setup == 0 ) {
                    swal(
                        $translate.instant('SETUP.NO_DEVICES'),
                        $translate.instant ('SETUP.NO_DEVICES_MSG'),
                        'warning'
                    );
                    setup.loading = false;
                }

                $window.location.href = '#/';
            }, function(error) {
                setup.loading = false;
            });
        }

        setup.getStyle = function() {
            var transform = (setup.isSemi ? '' : 'translateY(-50%) ') + 'translateX(-50%)';

            return {
                'top': setup.isSemi ? 'auto' : '50%',
                'bottom': setup.isSemi ? '5%' : 'auto',
                'left': '50%',
                'transform': transform,
                '-moz-transform': transform,
                '-webkit-transform': transform,
                'font-size': setup.radius/3.5 + 'px'
            };
        };

        setup.getColor = function() {
            return setup.gradient ? 'url(#gradient)' : setup.currentColor;
        };
}]);