angular.module('ShaasApp.controllers.DoughnutChartsController', [
        'ShaasApp.services.ConnectorService',
        'ShaasApp.services.UtilsService',
        'chart.js'
])

.controller('DoughnutChartsController', [
    'utils',
    '$translate',
    '$routeParams',
    '$timeout',
    '$scope',
    '$rootScope',
    'BootService',
    '$location',
    '$window',
    '$route',
    'ConnectorNoRest',
    'ConnectorRest',
    function(utils, $translate, $routeParams, $timeout, $scope, $rootScope, BootService, $location, $window, $route, ConnectorNoRest, ConnectorRest) {

        var charts = this;
        var units = 'Kb';
        var total_bandwidth = undefined;
        var sync = false;

        charts.get_data = function() {
            connector = $rootScope.connector;
            if (sync == true) {
                return;
            }
            // Block many updates with xpull for 3s
            sync = true;
            $timeout( function() { sync = false }, 3000);

            // Set app is premium or free
            $rootScope.checkRegistration($rootScope.uuid());

            charts.series = [];
            charts.labels = [];
            charts.data = {
                'up': [],
                'down': []
            };
            charts.bandwidth = {
                'up': {
                    'bw': 0,
                    'units': units
                },
                'down': {
                    'bw': 0,
                    'units': units
                }
            };

            charts.options = {
                'up': {
                    animateRotate : false,
                    tooltipTemplate: '<%if (label){%><%=label%>: <%}%><%= value %> ' + units,
                    legendTemplate: '<ul class=\"<%=name.toLowerCase()%>-legend\">' +
                                        '<% for (var i=0; i<segments.length; i++){%>' +
                                            '<li>' +
                                                '<span class="doughnut-legend__color" style=\"background-color:<%=segments[i].fillColor%>\"></span>' +
                                                '<span class="doughnut-legend__label"><%if(segments[i].label){%><%=segments[i].label%><%}%></span>' +
                                                '<i class=\"doughnut-legend__icon icon fa fa-long-arrow-up\">  <%=segments[i].value %> ' + units + '</i>' +
                                            '</li>' +
                                        '<%}%>' +
                                    '</ul>'
                },
                'down': {
                    animateRotate : false,
                    tooltipTemplate: '<%if (label){%><%=label%>: <%}%><%= value %> ' + units,
                    legendTemplate: '<ul class=\"<%=name.toLowerCase()%>-legend\">' +
                                        '<% for (var i=0; i<segments.length; i++){%>' +
                                            '<li>' +
                                                '<span class="doughnut-legend__color" style=\"background-color:<%=segments[i].fillColor%>\"></span>' +
                                                '<span class="doughnut-legend__label"><%if(segments[i].label){%><%=segments[i].label%><%}%></span>' +
                                                '<i class=\"doughnut-legend__icon fa fa-long-arrow-down\">  <%=segments[i].value%> ' + units + '</i>' +
                                            '</li>' +
                                        '<%}%>' +
                                    '</ul>'
                }
            }

            connector.classes_root().then(function(response) {
                if (!response.status == 200) {
                    return;
                }
                total_bandwidth = response.data.classes.down.max;

                connector.classes_get_all().then(function(response) {
                    if (response.status == 204) {
                        swal({
                            title: $translate.instant('CLASSES.EMPTY'),
                            text: $translate.instant('CLASSES.EMPTY_MSG'),
                            type: 'info',
                            showCancelButton: true,
                            confirmButtonText: $translate.instant('CLASSES.GO'),
                            cancelButtonText: $translate.instant('CLASSES.NOT_GO')
                        }, function() {
                            $window.location.href = '#/setup';
                        });
                        return;
                    } else if (response.status == 500) {
                        swal(
                            $translate.instant('CLASSES.ERROR'),
                            $translate.instant('CLASSES.GENERAL_ERROR'),
                            'error'
                        );
                    }
                    _.each(response.data.classes, function(value, key) {
                        if(value.down.rate == undefined){
                          value.down.rate="0"
                        }
                        if(value.up.rate == undefined){
                          value.up.rate="0"
                        }
                    });
                    listOrderDown = _.sortBy(response.data.classes, function (num) { return Number(num.down.rate); }).reverse();

                    _.each(listOrderDown, function(value, key) {
                        if (key == '0') {

                            charts.bandwidth.down = {
                                'bw': utils.bitsToAny(
                                        value.down.rate,
                                        units,
                                        total_bandwidth
                                    ).bandwidth,
                                'units': units
                            };

                            return true; //idem to continue
                        }

                        charts.series.push(value._meta.description || $translate.instant('GENERAL.DEVICE'));

                        if (value.down.rate == 0) {
                            charts.data.down.push(0);
                        } else {
                            charts.data.down.push(utils.bitsToAny(value.down.rate, units, total_bandwidth).bandwidth);
                        }
                    });

                    listOrderUp = _.sortBy(response.data.classes, function (num) { return Number(num.up.rate); }).reverse();

                    _.each(listOrderUp, function(value, key) {
                        if (key == '0') {
                            charts.bandwidth.up = {
                                'bw': utils.bitsToAny(
                                        value.up.rate,
                                        units,
                                        total_bandwidth
                                    ).bandwidth,
                                'units': units
                            };

                            return true; //idem to continue
                        }

                        if (value.up.rate == 0) {
                            charts.data.up.push(0);
                        } else {
                            charts.data.up.push(utils.bitsToAny(value.up.rate, units, total_bandwidth).bandwidth);
                        }
                    });

                    charts.labels = charts.series;

                }, function(error) {
                    swal({
                        title: $translate.instant('CLASSES.ERROR'),
                        text: $translate.instant('CLASSES.EMPTY_MSG'),
                        type: 'info',
                        html: true
                    });
                    return;
                });
            }, function(error) {
                if (error.status == -1){
                    var promise = BootService.discover();
                    promise.then(function(promise) {
                        if (promise == 'ConnectorNoRest') {
                            $rootScope.connector = ConnectorNoRest;
                        } else if (promise == 'ConnectorRest') {
                            $rootScope.connector = ConnectorRest;
                        }
                        $location.path('/consumption');
                        if ($rootScope.isPremium()) {
                            $route.reload();
                        } else {
                            swal({
                                title: $translate.instant('GENERAL.ERROR_REMOTE'),
                                text: $translate.instant('GENERAL.ERROR_MGE'),
                            });
                        }
                    }, function(reason) {
                    });
                }
            });
        };

        charts.get_data();

}]);
