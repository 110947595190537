angular.module('xpull', [])

.directive('ngXpull', function() {
    return function(scope, elm, attr) {
        return $(elm[0]).xpull({
            'spinnerTimeout': 2000,
            'callback': function() {
                return scope.$apply(attr.ngXpull);
            }
        });
    };
});
