angular.module('ShaasApp.services.ConnectorService', ['restangular'])

// BootService
.service('BootService', function(Restangular, config, store, ConnectorNoRest, ConnectorRest, $rootScope, $q, $controller, $location, $route) {
    var base_url = config.apis.cpe;

    _construct = function() {
        return Restangular
            .withConfig(function(RestangularConfigurer) {
                RestangularConfigurer.setBaseUrl(base_url);
                RestangularConfigurer.setFullResponse(true);
                RestangularConfigurer.setDefaultHeaders({
                  'Content-Type': 'application/json'
                })
             });
    };

    this.discover = function() {
        base_url = config.apis.cpe;

        return $q(function(resolve, reject) {

            // Set for last_router value the previous cpe uuid.
            uuid = store.get('router');
            set_last_router(uuid);

            router = store.get(uuid);
            // Initialize again.
            if (uuid && !router) {
                store.remove('router');
                window.location.reload();
            }

            if (uuid == null || !router.deleted) {
                response = self._construct()
                    .one('/cgi-bin/luci/shaas-api/uuid')
                    .get();

                response.then(function(response) {
                    uuid = response.data;
                    routerTouch = store.get('router');
                    routerRemote = store.get('remote');

                    if ((routerRemote != null && store.get(routerTouch).remote && uuid != routerTouch) && store.get(uuid) != null) {
                        response = connect_remote(routerTouch, routerRemote, {});
                        if (response == 'Unreachable') {
                            reject('Unreachable')
                        }
                        resolve(response);
                    } else {
                        if (!store.get(uuid)) {
                            store.set('router', uuid);
                            response = self._construct()
                               .one('/cgi-bin/luci/shaas-api/capabilities')
                               .get();
                                response.then(function(response) {
                                    if (response.status == 200) {
                                        data = response.data;
                                        base_url = base_url + data.endpoints[0].uri;
                                        // uuid = data.endpoints[0].uuid;
                                        if (data.endpoints != undefined && data.endpoints[0].type == 'shaas') {
                                            values = {
                                                'token': null,
                                                'version': '15',
                                                'uuid': uuid,
                                                'url': 'https://shaas.loc/cgi-bin/luci/shaas-api',
                                                'name': '',
                                                'deleted': false,
                                                'logged': false,
                                                'remote': false,
                                                'premium': true
                                            };
                                            // Identify new router, so we can change.
                                            if ($location.path() != '/login') {
                                                store.set('change_router', uuid);
                                            }
                                            store.set(uuid, values);
                                            resolve('ConnectorNoRest');
                                            store.remove('remote');
                                        }
                                    }
                                }, function(error) {
                                    reject('An error occurred when wanting to communicate with the router');
                                })
                        } else {
                            if (!store.get(uuid).deleted) {
                                store.set('router', uuid);
                            }

                            remote = $rootScope.uuidRemote();
                            router = $rootScope.uuid();

                            if (router) {
                                store.get(router).remote = false;
                                store.set(router, store.get(router));
                                if (!remote || !store.get(router).remote) {

                                    base_url = store.get(router).url;
                                    resolve('ConnectorNoRest');
                                    store.remove('remote');
                                } else {
                                    // On Login view & Local unknown (deleted=true) but we want to connect Remote.
                                    response = connect_remote(router, remote, {});
                                    if (response == 'Unreachable') {
                                        reject('Unreachable')
                                    }
                                    resolve(response);
                                }
                            } else {
                                $rootScope.$broadcast('selectConnect');
                            }
                        }
                    }
                }, function(error) {
                    response = self._construct()
                        .one('/0.1/uuid')
                        .get();
                    response.then(function(response) {
                        uuid = response.data;
                        routerTouch = store.get('router');
                        routerRemote = store.get('remote');

                        if ((routerRemote != null && store.get(routerTouch).remote && uuid != routerTouch) && store.get(uuid) != null) {
                            response = connect_remote(routerTouch, routerRemote, {});
                            if (response == 'Unreachable') {
                                reject('Unreachable')
                            }
                            resolve(response);
                        } else {
                            if (response.status == 200) {
                                if (!store.get(uuid)) {
                                    store.set('router', uuid);
                                    base_url = base_url + '0.1';
                                    values = {
                                        'token': null,
                                        'version': '14',
                                        'uuid': uuid,
                                        'url': base_url,
                                        'name': '',
                                        'deleted': false,
                                        'logged': false,
                                        'remote': false,
                                        'premium': true
                                    };
                                    // Identify new router, so we can change.
                                    if ($location.path() != '/login') {
                                        store.set('change_router', uuid);
                                    }
                                    store.set(uuid, values);
                                    resolve('ConnectorRest');
                                    store.remove('remote');
                                } else {
                                    if (!store.get(uuid).deleted) {
                                        store.set('router', uuid);
                                    }
                                    remote = $rootScope.uuidRemote();
                                    router = $rootScope.uuid();

                                    if (router) {
                                        store.get(router).remote = false;
                                        store.set(router, store.get(router));
                                        if (!remote || !store.get(router).remote) {

                                            base_url = base_url + '0.1';
                                            resolve('ConnectorRest');
                                            store.remove('remote');
                                        } else {
                                            // On Login view & Local unknown (deleted=true) but we want to connect Remote.
                                            response = connect_remote(router, remote, {});
                                            if (response == 'Unreachable') {
                                                reject('Unreachable')
                                            }
                                            resolve(response);
                                        }
                                    } else {
                                        $rootScope.$broadcast('selectConnect');
                                    }
                                }
                            }
                        }
                    }, function(error) {
                        if (store.get(store.get('last_router'))) {
                            last_router = store.get('last_router');
                        }
                        response = connect_remote($rootScope.uuid(), last_router, error);
                        if (response == 'Unreachable') {
                            reject('Unreachable')
                        }
                        resolve(response);
                    });
                });
            } else {
                error = {}
                error.status = 400;
                if (store.get(store.get('last_router'))) {
                    last_router = store.get('last_router');
                }
                response = connect_remote(store.get('router'), last_router, error);
                if (response == 'Unreachable') {
                    reject('Unreachable')
                }
                resolve(response);
            }
        });
    }

    connect_remote = function(uuid, uuidRemote, error) {
        if (store.get(uuid)) {
            if (store.get(uuid).token != null) {
                // Have premium tunnel feature enable?
                if (error.status == 502) {
                    console.log("Not premium feature enabled, contact support (123:tunnel)");
                } else {
                    if (uuidRemote) {
                        if (store.get(uuidRemote).version == '15') {
                            $rootScope.connector = ConnectorNoRest;
                            url = 'cgi-bin/luci/shaas-api';
                            connectorNow = 'ConnectorNoRest';
                        } else if (store.get(uuidRemote).version == '14') {
                            $rootScope.connector = ConnectorRest;
                            url = '0.1';
                            connectorNow = 'ConnectorRest';
                        }
                    }
                    connector = $rootScope.connector;
                    store.set('remote', uuidRemote);
                    var url_remote = config.apis.remote.replace("%s", uuidRemote);
                    url_remote = url_remote + url;
                    connector.set_base_url(url_remote);
                    return connectorNow;
                }
            // Is logout and try to connect remote.
            } else {
                if (uuidRemote) {
                    if (store.get(uuidRemote).version == '15') {
                        $rootScope.connector = ConnectorNoRest;
                        url = 'cgi-bin/luci/shaas-api';
                        connectorNow = 'ConnectorNoRest';
                    } else if (store.get(uuidRemote).version == '14') {
                        $rootScope.connector = ConnectorRest;
                        url = '0.1';
                        connectorNow = 'ConnectorRest';
                    }
                }
                connector = $rootScope.connector;
                store.set('remote', uuidRemote);
                store.set('router', uuidRemote);
                var url_remote = config.apis.remote.replace("%s", uuidRemote);
                url_remote = url_remote + url;
                connector.set_base_url(url_remote);
                return connectorNow;
            }

        } else {
            $rootScope.$broadcast('shaas_unreachable');
            return 'Unreachable';
        }
    }

    set_last_router = function(uuid) {
        last_router = store.get('last_router');
        if (last_router == "null" || last_router == null || last_router != uuid) {
            store.set('last_router', uuid);
        }
    }

    this.discover_remote = function(base_url_param) {
        base_url = base_url_param;

        if (base_url == config.demo.url) {
            ConnectorRest.set_base_url(base_url);
        }

        if (connector) {
            connector.set_base_url(base_url);
        }

        return $q(function(resolve, reject) {

            uuidNow = $rootScope.uuid();
            routerNow = store.get(uuidNow);

            if (routerNow) {
                routerNow.deleted = true;
                store.set(uuidNow, routerNow);
            }

            response = self._construct()
                .one('/cgi-bin/luci/shaas-api/uuid')
                .get();

            response.then(function(response) {
                uuid = response.data;

                if (!store.get(uuid)) {
                    store.set('router', uuid);
                    store.set('remote', uuid);
                    response = self._construct()
                       .one('/cgi-bin/luci/shaas-api/capabilities')
                       .get();
                        response.then(function(response) {
                            if (response.status == 200) {
                                data = response.data;
                                base_url = base_url + data.endpoints[0].uri;
                                // uuid = data.endpoints[0].uuid;
                                if (data.endpoints != undefined && data.endpoints[0].type == 'shaas') {
                                    values = {
                                        'token': null,
                                        'version': '15',
                                        'uuid': uuid,
                                        'url': 'https://shaas.loc/cgi-bin/luci/shaas-api',
                                        'name': '',
                                        'deleted': false,
                                        'logged': false,
                                        'remote': false,
                                        'premium': true
                                    };
                                    // Identify new router, so we can change.
                                    if ($location.path() != '/login') {
                                        store.set('change_router', uuid);
                                    }

                                    store.set(uuid, values);

                                    router = store.get(uuid);
                                    router.remote = true;
                                    store.set(uuid, router);

                                    resolve('ConnectorNoRest');
                                }
                            }
                        }, function(error) {
                            reject('An error occurred when wanting to communicate with the router');
                        })
                } else {
                    router = store.get(uuid);

                    if (router && router.token == null) {

                        store.set('router', uuid);
                        store.set('remote', uuid);

                        if (router.version == '15') {
                            $rootScope.connector = ConnectorNoRest;
                            connectorNow = 'ConnectorNoRest';
                        } else if (router.version == '14') {
                            $rootScope.connector = ConnectorRest;
                            connectorNow = 'ConnectorRest';
                        }
                        connector = $rootScope.connector;

                        router.remote = true;

                        resolve(connectorNow);

                    } else {
                        var params = $location.search();
                        if (params.uuid != undefined) {
                            // $rootScope.$broadcast('selectConnect');
                        }
                    }
                }
            }, function(error) {
                response = self._construct()
                    .one('/0.1/uuid')
                    .get();
                response.then(function(response) {
                    uuid = response.data;

                    if (!store.get(uuid)) {
                        store.set('router', uuid);
                        base_url = base_url + '0.1';
                        values = {
                            'token': null,
                            'version': '14',
                            'uuid': uuid,
                            'url': base_url,
                            'name': '',
                            'deleted': false,
                            'logged': false,
                            'remote': false,
                            'premium': true
                        };
                        // Identify new router, so we can change.
                        if ($location.path() != '/login') {
                            store.set('change_router', uuid);
                        }
                        store.set(uuid, values);

                        router = store.get(uuid);
                        router.remote = true;
                        store.set(uuid, router);

                        resolve('ConnectorRest');
                    } else {
                        router = store.get(uuid);

                        if (router && router.token == null) {

                            store.set('router', uuid);
                            store.set('remote', uuid);

                            if (router.version == '15') {
                                $rootScope.connector = ConnectorNoRest;
                                connectorNow = 'ConnectorNoRest';
                            } else if (router.version == '14') {
                                $rootScope.connector = ConnectorRest;
                                connectorNow = 'ConnectorRest';
                            }
                            connector = $rootScope.connector;

                            router.remote = true;

                            resolve(connectorNow);

                        } else {
                            var params = $location.search();
                            if (params.uuid != undefined) {
                                // $rootScope.$broadcast('selectConnect');
                            }
                        }
                    }
                }, function(error) {
                    reject('Unreachable');
                });
            });
        });
    }

})

// Cpe Connector Rest
.factory('ConnectorRest', function(Restangular, config, store, $rootScope) {
    var ConnectorRest;
    var base_url = config.apis.cpe;

    _constructRest = function() {
        if (base_url == config.demo.url) {
            base_url = base_url + '0.1';
        }
        if (store.get($rootScope.uuid())) {
            uuid = store.get($rootScope.uuid()).uuid;
            jwt = store.get(uuid).token;
            if (!$rootScope.uuidRemote()) {
                base_url = store.get(uuid).url;
            }
        } else {
            var jwt = null;
        }
        return Restangular
            .withConfig(function(RestangularConfigurer) {
                RestangularConfigurer.setBaseUrl(base_url);
                RestangularConfigurer.setFullResponse(true);
                RestangularConfigurer.setDefaultHeaders({
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + jwt
                })
            });
    };

    ConnectorRest = {
        set_base_url: function (base_url_param) {
            base_url = base_url_param;
        },
        credentials: function() {
            return self._constructRest()
                .one('credentials')
                .get();
        },
        status: function() {
            return self._constructRest()
                .one('status')
                .get();
        },
        classes_get_all: function() {
            return self._constructRest()
                .one('classes')
                .get();
        },
        classes_root: function() {
            return self._constructRest()
                .one('classes/root')
                .get();
        },
        classes_get: function(id) {
            return self._constructRest()
                .one('classes', id)
                .get();
        },
        classes_update: function(data, id) {
            jData = JSON.stringify(data);
            return self._constructRest()
                .one('classes')
                .customPUT(jData, id);
        },
        classes_suspend: function(ip) {
            return self._constructRest()
                .one('suspension', ip)
                .customPOST();
        },
        classes_unsuspend: function(ip) {
            return self._constructRest()
                .one('suspension', ip).
                customDELETE();
        },
        classes_delete: function(id) {
            return self._constructRest()
                .one('classes', id)
                .customDELETE();
        },
        setup: function(bandwidth) {
            return self._constructRest()
                .one('setup', bandwidth)
                .customPOST();
        },
        shaas_on: function() {
            return self._constructRest()
                .one('boot')
                .customPOST();
        },
        shaas_off: function() {
            return self._constructRest()
                .one('boot')
                .customDELETE();
        },
        whoami: function() {
            return self._constructRest()
                .one('whoami')
                .get();
        },
        login: function(data) {
            jData = JSON.stringify(data);
            return self._constructRest()
                .one('auth')
                .customPOST(jData);
        },
        uuid: function() {
            return self._constructRest()
                .one('uuid')
                .get();
        },
        guests_get: function() {
            return self._constructRest()
                .one('guests')
                .get();
        },
        guests_on: function() {
            return self._constructRest()
                .one('guests')
                .customPOST();
        },
        guests_off: function() {
            return self._constructRest()
                .one('guests')
                .customDELETE();
        },
        wifi_get: function() {
            return self._constructRest()
                .one('wifi')
                .get();
        },
        wifi_update: function(data, index) {
            jData = JSON.stringify(data);
            return self._constructRest()
                .one('wifi')
                .customPUT(jData, index);
        },
        plugins: function(data) {
            if (data != undefined) {
                var blob = new Blob([data], {type : 'text/lua'});
                uuid = $rootScope.uuid();
                router = store.get(uuid);
                if (router && router.token) {
                    uuid = router.uuid;
                    if (!$rootScope.uuidRemote()) {
                        base_url = router.url;
                    }
                    jwt = router.token;
                } else {
                    jwt = null;
                }
                formData = new FormData()
                formData.append('file', blob)

                return Restangular
                    .withConfig(function(RestangularConfigurer) {
                        RestangularConfigurer.setBaseUrl(base_url);
                        RestangularConfigurer.setFullResponse(true);
                        RestangularConfigurer.setDefaultHeaders({
                            'Authorization': 'Bearer ' + jwt
                        });

                    })
                    .one('plugins')
                    .customPOST(formData, undefined, undefined, {'Content-Type': undefined});
            }
        },
    };
    return ConnectorRest
})

// Cpe Connector Not Rest
.factory('ConnectorNoRest', function(Restangular, config, store, $rootScope) {
    var ConnectorNoRest
    var base_url = config.apis.cpe;

    _constructNoRest = function() {
        if (store.get($rootScope.uuid())) {
            uuid = store.get($rootScope.uuid()).uuid;
            jwt = store.get(uuid).token;
            if (!$rootScope.uuidRemote()) {
                base_url = store.get(uuid).url;
            }
         } else {
            var jwt = null;
         }
        return Restangular
            .withConfig(function(RestangularConfigurer) {
                RestangularConfigurer.setBaseUrl(base_url);
                RestangularConfigurer.setFullResponse(true);
                RestangularConfigurer.setDefaultHeaders({
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + jwt
                })
             });
    };

    ConnectorNoRest = {

        set_base_url: function (base_url_param) {
            base_url = base_url_param;
        },
        add_other_shaas: function(uuidRemote) {
            store.set('router', uuidRemote);
            store.set('remote', uuidRemote);
        },
        credentials: function() {
            return self._constructNoRest()
                .one('credentials')
                .get();
        },
        status: function() {
            return self._constructNoRest()
                .one('status')
                .get();
        },
        classes_get_all: function() {
            return self._constructNoRest()
                .one('classes')
                .get();
        },
        classes_root: function() {
            return self._constructNoRest()
                .one('classes/root')
                .get();
        },
        shaas_on: function() {
            return self._constructNoRest()
                .one('boot')
                .customPOST();
        },
        whoami: function() {
            return self._constructNoRest()
                .one('whoami')
                .get();
        },
        login: function(data) {
            jData = JSON.stringify(data);
            return self._constructNoRest()
                .one('auth')
                .customPOST(jData);
        },
        uuid: function() {
            return self._constructNoRest()
                .one('uuid')
                .get();
        },
        guests_get: function() {
            return self._constructNoRest()
                .one('guests')
                .get();
        },
        guests_on: function() {
            return self._constructNoRest()
                .one('guests')
                .customPOST();
        },
        classes_get: function(id) {
            return self._constructNoRest()
                .all('classes')
                .customGET('', { class_id: id });
        },
        classes_update: function(data, id) {
            jData = JSON.stringify(data);
            return self._constructNoRest()
                .all('classes')
                .customPOST(jData, '', { action: 'PUT', class_id: id });
        },
        classes_suspend: function(ip) {
            return self._constructNoRest()
                .all('suspension')
                .customPOST('', '', { ip: ip });
        },
        classes_unsuspend: function(ip) {
            return self._constructNoRest()
                .all('suspension')
                .customGET('', { action: 'DELETE', ip: ip });
        },
        classes_delete: function(id) {
            return self._constructNoRest()
                .all('classes')
                .customGET('', { action: 'DELETE', class_id: id });
        },
        shaas_off: function() {
            return self._constructNoRest()
                .all('boot')
                .customGET('', { action: 'DELETE' });
        },
        guests_off: function() {
            return self._constructNoRest()
                .all('guests')
                .customGET('', { action: 'DELETE' });
        },
        setup: function(bandwidth) {
            return self._constructNoRest()
                .all('setup')
                .customPOST('', '', { bw_total: bandwidth });
        },
        wifi_get: function() {
            return self._constructNoRest()
                .one('wifi')
                .get();
        },
        wifi_update: function(data, id) {
            jData = JSON.stringify(data);
            return self._constructNoRest()
                .all('wifi')
                .customPOST(jData, '', { action: 'PUT', index: id });
        },
        plugins: function(data) {
            if (data != undefined) {
                var blob = new Blob([data], {type : 'text/lua'});
                uuid = $rootScope.uuid();
                router = store.get(uuid);
                if (router && router.token){
                    uuid = router.uuid;
                    if (!$rootScope.uuidRemote()) {
                        base_url = router.url;
                    }
                    jwt = router.token;
                } else {
                    jwt = null;
                }
                formData = new FormData()
                formData.append('file', blob)

                return Restangular
                    .withConfig(function(RestangularConfigurer) {
                        RestangularConfigurer.setBaseUrl(base_url);
                        RestangularConfigurer.setFullResponse(true);
                        RestangularConfigurer.setDefaultHeaders({
                            'Authorization': 'Bearer ' + jwt
                        });

                    })
                    .one('plugins')
                    .customPOST(formData, undefined, undefined, {'Content-Type': undefined});
            }
        },
    }
    return ConnectorNoRest
})

.factory('CloudConnector', function(Restangular, config, store, $rootScope) {
    var CloudConnector;

    _cloud_construct = function() {
        controllerCloudUuid = store.get('controllerCloud' + $rootScope.uuid());
        if (controllerCloudUuid != undefined) {
            var jwt = controllerCloudUuid.access_token;
        } else {
            var jwt = null;
        }

        return Restangular
            .withConfig(function(RestangularConfigurer) {
                RestangularConfigurer.setBaseUrl(config.apis.cloud);
                RestangularConfigurer.setFullResponse(true);
                RestangularConfigurer.setDefaultHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT ' + jwt
                })
            });
    };

    CloudConnector = {
        // plugins: function(id, data) {
        //     jData = JSON.stringify(data);
        //     return _cloud_construct()
        //         .one('plugins', id)
        //         .customPOST(jData);
        // },
        plugins: function(hash) {
            return _cloud_construct()
                .one('plugins', hash)
                .get();
        },
        signal_get: function(uuid, params, hash){
          return _cloud_construct()
            .one('cpe/' + uuid + '/plugins?hash=' + hash)
            .get(params);
        },
        controller_login: function(data) {
            jData = JSON.stringify(data);
            return _cloud_construct()
                .one('auth')
                .customPOST(jData);
        },
        check_registration: function(uuid) {
            return _cloud_construct()
                .one('registrations/cpe', uuid)
                .get();
        },
        associate_email: function(data) {
            jData = JSON.stringify(data);
            return _cloud_construct()
                .one('registrations/cpe')
                .customPOST(jData);
        },
        resellers_login: function(data) {
            jData = JSON.stringify(data);
            return _cloud_construct()
                .one('resellers/login')
                .customPOST(jData);
        },
        reseller_get: function(token) {
            return _cloud_construct()
                .one('resellers', token)
                .get();
        },
        controllers_get: function(token) {
            token = token + "/registrations";
            return _cloud_construct()
                .one('resellers', token)
                .get();
        },
        create_controller: function(token, data) {
            token = token + "/registrations";
            jData = JSON.stringify(data);
            return _cloud_construct()
                .one('resellers', token)
                .customPOST(jData);
        },
    };

    return CloudConnector;
})
