angular.module('ShaasApp.services.UtilsService', [])

.factory('utils', function() {
    utils = {};

    var bitsToAny = function(bits, measure, total_bits) {
        if (isNaN(parseFloat(bits)) || !isFinite(bits) || bits == 0) return '0';

        var floor;
            precision = 1;
            units = {
                1: 'Kb',
                2: 'Mb',
                3: 'Gb',
                4: 'Tb'
            };

        if (typeof measure != 'undefined') {
            measure = _.invert(units)[measure];
        } else {
            var measure;

            if (bits > 1000000000000) {
                measure = 4;
            } else if (bits > 1000000000 && bits <= 1000000000000) {
                measure = 3;
            } else if (bits > 1000000 && bits <= 1000000000) {
                measure = 2;
            } else if (bits <= 1000000) {
                measure = 1;
            }
        }

        bandwidth = (bits / Math.pow(1000, measure)).toFixed(precision);

        data = {
            'bandwidth': bandwidth,
            'units': units[measure],
        };
        if (typeof total_bits != 'undefined') {
            total_bandwidth = (total_bits / Math.pow(1000, measure)).toFixed(precision);
            data.percentage = ((data.bandwidth * 100) / total_bandwidth).toFixed(2);
        }

        return data;
    };

    var anyToBits = function(value, units) {
        if (isNaN(parseFloat(value)) || !isFinite(value) || value == 0) return '0';

        var floor,
            precision,
            units_measure = {
                'Kb': 1,
                'Mb': 2,
                'Gb': 3,
                'Tb': 4
            };

        measure = units_measure[units]

        floor = Math.floor(value / Math.pow(1000, measure)).toString().length;
        if (floor > 3) {
            precision = 0
        } else {
            precision = 3 - floor;
        }

        return (value * Math.pow(1000, measure)).toFixed(precision);
    };

    var conversion = function(valueToConvert, func, units) {
        // down stream
        // conversion_down_min = func(valueToConvert.down.min, units);
        conversion_down_max = func(valueToConvert.down.max, units);
        // valueToConvert.down.min = conversion_down_min['bandwidth'] ? conversion_down_min['bandwidth'] : conversion_down_min;
        valueToConvert.down.max = conversion_down_max['bandwidth'] ? conversion_down_max['bandwidth'] : conversion_down_max;

        // up stream
        // conversion_up_min = func(valueToConvert.up.min, units);
        conversion_up_max = func(valueToConvert.up.max, units);
        // valueToConvert.up.min = conversion_up_min['bandwidth'] ? conversion_up_min['bandwidth'] : conversion_up_min;
        valueToConvert.up.max = conversion_up_max['bandwidth'] ? conversion_up_max['bandwidth'] : conversion_up_max;

        return valueToConvert;
    };

    var setSliderValues = function(device, max, total_bandwith) {

        device.down.max = ((max * total_bandwith) / 100);
        device.up.max = ((max * total_bandwith) / 100);

        return device;
    };

    var checkAvailableBw = function(total, minimun_sum, device, old_value) {
        gap = null;

        // Difference between new value and actual value
        minimum_difference = parseInt(old_value) - parseInt(device.down.min);

        // Refresh minimum sum
        minimun_sum = minimun_sum - (minimum_difference);

        // Check if the minimum sum is greater than total
        gap = parseInt(minimun_sum) - parseInt(total);
        gap_percentage = ((gap * 100) / total).toFixed(2)

        return {
            'available': (parseInt(minimun_sum) <= parseInt(total)),
            'gap': (gap_percentage > 0) ? gap_percentage : null,
            'minimun_sum': minimun_sum
        }
    }

    var setPercentage = function(min_value, max_value, total_bandwith) {
        if (isNaN(parseFloat(min_value)) || !isFinite(min_value) || min_value == 0) return '0';
        if (isNaN(parseFloat(max_value)) || !isFinite(max_value) || max_value == 0) return '0';
        if (isNaN(parseFloat(total_bandwith)) || !isFinite(total_bandwith) || total_bandwith == 0) return '0';

        min = ((min_value * 100) / total_bandwith).toFixed(0);
        max = ((max_value * 100) / total_bandwith).toFixed(0);

        return {
            'min': parseInt(min),
            'max': parseInt(max)
        };
    }

    utils.bitsToAny = bitsToAny;
    utils.anyToBits = anyToBits;
    utils.conversion = conversion;
    utils.setSliderValues = setSliderValues;
    utils.checkAvailableBw = checkAvailableBw;
    utils.setPercentage = setPercentage;

    return utils;
});