angular.module('ShaasApp.controllers.Main', [
    'ShaasApp.services.ConnectorService'
])

.controller('MainController', [
    'config',
    'store',
    'CloudConnector',
    '$rootScope',
    'BootService',
    '$scope',
    'ConnectorNoRest',
    'ConnectorRest',
    function(config, store, CloudConnector, $rootScope, BootService, $scope, ConnectorNoRest, ConnectorRest) {

    var main = this;

    $rootScope.$on('connector', function(response) {
        connector = $rootScope.connector;
    })

    $rootScope.isDisabled = false;
    $rootScope.demo = true;
    $rootScope.total_bandwidth = 0;
    $rootScope.version = config.general.version;

    $rootScope.uuid = function() {
        if (store.get('router')) {
            if (store.get('router') != "[object Object]") {
                return store.get('router');
            }
            return false;
        }
        return false;
    }

    $rootScope.uuidRemote = function() {
        if (store.get(store.get('remote'))) {
            return store.get('remote');
        }
        return false;
    }

    $rootScope.uuidLastRouter = function() {
        if (store.get(store.get('last_router'))) {
            return store.get('last_router');
        }
        return false;
    }

    $rootScope.disableClick = function() {
        $rootScope.isDisabled = true;
        return false;
    }

    $rootScope.demo = function() {
        $rootScope.demo = true;
        return false;
    }

    $rootScope.switchBlock = function() {
        $rootScope.isBlocked = !$rootScope.isBlocked;
        return false;
    }

    $rootScope.isLogin = function() {
        router = store.get($rootScope.uuid());
        if (router == null) {
            return false;
        } else if (router.token == null) {
            return false;
        }
        return true;
    }

    $rootScope.activeDemo = function() {
        $rootScope.activateDemo = false;
        return false;
    }

    $rootScope.isPremium = function() {
    if ($rootScope.uuid()) {
        uuid = $rootScope.uuid();
        return store.get(uuid).premium;
    }
    return false;
    }

    $rootScope.checkRegistration = function(uuid) {
        if (uuid) {
            router_data = store.get(uuid)
            CloudConnector.check_registration(uuid).then(function(response) {
                router_data.premium = true;
                store.set(uuid, router_data);
            }, function(error) {
                router_data.premium = false;
                store.set(uuid, router_data);
            });
        }
    }

    $rootScope.registerCloud = function() {
        var controllerCloudUuid = "controllerCloud" + $rootScope.uuid();
        if (store.get(controllerCloudUuid)) {
            if (store.get(controllerCloudUuid).access_token != null) {
                return true;
            } else {
                return false;
            }
        } else {
            var promise = BootService.discover();
            promise.then(function(promise) {
                if (promise == 'ConnectorNoRest') {
                    $rootScope.connector = ConnectorNoRest;
                } else if (promise == 'ConnectorRest') {
                    $rootScope.connector = ConnectorRest;
                }
                connector = $rootScope.connector;
                connector.credentials().then(function(response) {
                    router = response.data;
                    loginCloudData = {
                        "username": response.data.uuid,
                        "password": response.data.jwt_console
                    }
                    CloudConnector.controller_login(loginCloudData).then(function(response) {
                        var controllerCloudUuid = "controllerCloud" + router.uuid;
                        store.set(controllerCloudUuid, response.data);
                        $rootScope.checkRegistration($rootScope.uuid());
                        // users.registration(router);
                    }, function(error) {
                        return
                    });
                }, function(response) {
                    return
                });
            }, function(reason) {
            });
        }
    }

    $rootScope.isLoginReseller = function() {
        reseller = store.get('reseller');
        if (reseller) {
            return true;
        }
        return false;
    }

    var last = {
        bottom: false,
        top: true,
        left: false,
        right: true
    };

    $scope.toastPosition = angular.extend({},last);
    $scope.getToastPosition = function() {
        sanitizePosition();
        return Object.keys($scope.toastPosition)
        .filter(function(pos) {
            return $scope.toastPosition[pos];
        })
    .join(' ');
    };

    function sanitizePosition() {
        var current = $scope.toastPosition;
        if ( current.bottom && last.top ) current.top = false;
        if ( current.top && last.bottom ) current.bottom = false;
        if ( current.right && last.left ) current.left = false;
        if ( current.left && last.right ) current.right = false;
        last = angular.extend({},current);
    }
}]);
