angular.module('ShaasApp', [
    'restangular',
    'ngRoute',
    'ngProgress',
    'mobile-angular-ui.components',
    'mobile-angular-ui.core.capture',
    'angularAwesomeSlider',
    'pascalprecht.translate',
    'xpull',
    'backButton',
    'angular-jwt',
    'angular-storage',
    'ngMaterial',
    'app.config',
    'ShaasApp.controllers.Main',
    'ShaasApp.controllers.HomeController',
    'ShaasApp.controllers.ClassesController',
    'ShaasApp.controllers.SetupController',
    'ShaasApp.controllers.ChartsController',
    'ShaasApp.controllers.SettingsController',
    'ShaasApp.controllers.DoughnutChartsController',
    'ShaasApp.controllers.UsersController',
    'ShaasApp.controllers.ProfilesController',
    'ShaasApp.controllers.RemotesController',
    'ShaasApp.controllers.WebAccessController',
    'ShaasApp.controllers.GuestsController',
    'ShaasApp.controllers.resellers.ResellersLoginController',
    'ShaasApp.controllers.resellers.ResellersController',
    'ShaasApp.controllers.WifiController',
    'ShaasApp.controllers.SignalController',
])

.config([
    '$routeProvider',
    '$translateProvider',
    function(
        $routeProvider,
        $translateProvider
    ) {

    // Routes
    $routeProvider
        .when('/', {
            templateUrl:'home.html',
            controller: 'HomeController as home',
            reloadOnSearch: false
        })
        .when('/classes', {
            templateUrl: 'classes.html',
            controller: 'ClassesController as classes',
            reloadOnSearch: false
        })
        .when('/device/:id', {
            templateUrl: 'device.html',
            controller: 'ClassesController as classes',
            reloadOnSearch: false
        })
        .when('/setup', {
            templateUrl: 'setup.html',
            controller: 'SetupController as setup',
            reloadOnSearch: false
        })
        .when('/charts/:id', {
            templateUrl: 'line-chart.html',
            controller: 'ChartsController as charts',
            reloadOnSearch: false
        })
        .when('/consumption', {
            templateUrl: 'doughnut-chart.html',
            controller: 'DoughnutChartsController as charts',
            reloadOnSearch: false
        })
        .when('/login', {
            templateUrl: 'login.html',
            controller: 'UsersController as users',
            reloadOnSearch: false
        })
        .when('/signal', {
            templateUrl: 'signal.html',
            controller: 'SignalController as profiles',
            reloadOnSearch: false
        })
        .when('/profiles', {
            templateUrl: 'profile.html',
            controller: 'ProfilesController as profiles',
            reloadOnSearch: false
        })
            .when('/profiles/movie', {
                templateUrl: 'elements/profiles/movie.html',
                controller: 'ProfilesController as profiles',
                reloadOnSearch: false
            })
            .when('/profiles/all_for', {
                templateUrl: 'elements/profiles/all_for.html',
                controller: 'ProfilesController as profiles',
                reloadOnSearch: false
            })
        .when('/settings', {
            templateUrl: 'settings.html',
            controller: 'SettingsController as settings',
            reloadOnSearch: false
        })
            .when('/settings/wifi', {
                templateUrl:'elements/settings/wifi.html',
                controller: 'WifiController as wifi',
                reloadOnSearch: false
            })
            .when('/settings/web_access', {
                templateUrl:'elements/settings/web_access.html',
                controller: 'WebAccessController as web_access',
                reloadOnSearch: false
            })
            .when('/settings/about', {
                templateUrl:'elements/settings/about.html',
                reloadOnSearch: false
            })
            .when('/settings/remotes', {
                templateUrl:'elements/settings/remote.html',
                controller: 'RemotesController as remote',
                reloadOnSearch: false
            })
        .when('/resellers/login', {
            templateUrl:'resellers/login.html',
            controller: 'ResellersLoginController as login',
            reloadOnSearch: false
        })
        .when('/resellers/home', {
            templateUrl:'resellers/home.html',
            controller: 'ResellersController as reseller',
            reloadOnSearch: false
        })
        .when('/elements/cpe_remotes', {
            templateUrl:'elements/select_remote.html',
            controller: 'RemotesController as remote',
            reloadOnSearch: false
        })
        .when('/elements/other_shaas', {
            templateUrl:'elements/other_shaas.html',
            controller: 'UsersController as users',
            reloadOnSearch: false
        });

    // Translations
    $translateProvider
        .useStaticFilesLoader({
            prefix: 'lang/',
            suffix: '.json'
        })
        .registerAvailableLanguageKeys(['en', 'es'], {
            'en_AU': 'en',
            'en_CA': 'en',
            'en_NZ': 'en',
            'en_US': 'en',
            'en_GB': 'en',
            'en_ZA': 'en',
            'es-419': 'es'
        })
        .determinePreferredLanguage()
        .fallbackLanguage('en')
        .useSanitizeValueStrategy('escaped');

}])

.run(function(Restangular, config, ngProgress, store, jwtHelper, $route, $location, $rootScope, BootService, ConnectorNoRest, ConnectorRest, CloudConnector) {

    // Use for avoid repeat requests.
    var onlyOnce = true;
    var onlyOnceLogin = true;
    var onlyOnceSelector = true;

    // router = $rootScope.uuid();
    router = store.get('router');
    remote = store.get('remote');

    // Clean localStorage
    if (router && !_.isObject(store.get(router))) {
        window.localStorage.clear();
        location.reload();
        return false;
    }

    var params = $location.search();

    // Set uuid by querystring
    if (params.uuid != undefined) {
        window.localStorage.clear();
        store.set("remote", params.uuid);
        // store.set("router", params.uuid);
        $rootScope.$broadcast('setWebRemote');
        return;
    }

    if (!router || remote || $location.path() == '/login') {
        var promise = BootService.discover();
        promise.then(function(promise) {
            if (promise == 'ConnectorNoRest') {
                $rootScope.connector = ConnectorNoRest;
            } else if (promise == 'ConnectorRest') {
                $rootScope.connector = ConnectorRest;
            }
        }, function(reason) {
            if (onlyOnce) {
                onlyOnce = false;
                window.location.href = '#/';
            }
        });
    } else {
        if (store.get(router) != undefined) {
            if (store.get(router).version == '15') {
                $rootScope.connector = ConnectorNoRest;
            } else if (store.get(router).version == '14') {
                $rootScope.connector = ConnectorRest;
            }
        }
    }

    $rootScope.$on('$routeChangeStart', function(e, to) {
        if (to.originalPath !== '/login') {
            // Escape authentication for reseller.
            if (to.$$route.templateUrl.search('resellers/*') != -1) {
                return
            }
            router = store.get($rootScope.uuid());
            if (router == null || router.token == null || jwtHelper.isTokenExpired(router.token)) {
                if (!store.get('change_router')) {
                    $location.path('/login');
                    e.preventDefault();
                }
            }
        }
    });

    Restangular.addRequestInterceptor(function(
        element,
        operation,
        what,
        url
    ) {
        ngProgress.color('#FF4081');
        ngProgress.height('5px');
        if (
            what == 'setup' || (what == 'classes' && _.isUndefined(element.id)) ||
            what == 'plugins' || (what == 'classes' && element.id == '1_2')
        ) {
            ngProgress.start();
        }
        return element;
    });

    Restangular.addResponseInterceptor(function(
        data,
        operation,
        what,
        url,
        response,
        deferred
    ) {
        var pattern = new RegExp("/classes/[0-9]_[0-9]*$");
        var root_pattern = new RegExp("/classes/1_2$");
        if (
            what == 'setup' || (what == 'classes' && !pattern.test(url)) ||
            what == 'plugins' || (what == 'classes' && root_pattern.test(url))
        ) {
            ngProgress.complete();
            $rootScope.isDisabled = false;
            $rootScope.demo = false;
        }
        return data;
    });

    Restangular.addRequestInterceptor(function(
        element,
        operation,
        what,
        url
    ) {
        if (onlyOnce) {
            onlyOnce = false;
            var n = url.search("remote");
            if (n == -1) {
            } else {
                var promise = BootService.discover();
                promise.then(function(promise) {
                    if (promise == 'ConnectorNoRest') {
                        $rootScope.connector = ConnectorNoRest;
                    } else if (promise == 'ConnectorRest') {
                        $rootScope.connector = ConnectorRest;
                    }
                }, function(reason) {
                });
            }
        }
        return element;
    });

    Restangular.setErrorInterceptor(function(response, deferred, responseHandler) {
        connector = $rootScope.connector;

        // App work on remote network.
        if(response.status === -1 && $location.path() != '/login') {
            if (onlyOnce) {
                onlyOnce = false;
                var url = config.apis.remote.replace("%s", $rootScope.uuid);
                connector.set_base_url(url);
                $location.path('/');
            }
        }

        // App always work on local network.
        if(response.status === 401) {
            if ($location.path() != '/login') {
                router = store.get('router');
                if (router) {
                    connector.uuid().then(function(response) {
                        router_alive = store.get(response.data)
                        if (response.status != 200) {
                            // Api fails
                            $location.path('/login');
                        } else {
                            if (router.uuid != response.data) {
                                if (router_alive == null) {
                                    // It is a cpe that never connect before
                                    if (onlyOnceLogin) {
                                        onlyOnceLogin = false;
                                        $rootScope.$broadcast('otherShaas');
                                    }
                                } else {
                                    // It is a cpe that connect once, so change to this cpe
                                    if (router_alive.token != null && !router_alive.deleted) {
                                        if (onlyOnceSelector) {
                                            onlyOnceSelector = false;
                                            $rootScope.$broadcast('selectConnect');
                                        }
                                    }
                                }
                                $location.path('/');
                            } else {
                                if (router_alive != null) {
                                    if (router_alive.token != null) {
                                        if (jwtHelper.isTokenExpired(router_alive.token)) {
                                            // Remove jwt from specify router because token is expired.
                                            store.remove(router);
                                            $location.path('/login');
                                        }
                                    } else {
                                        if (onlyOnceLogin) {
                                            onlyOnceLogin = false;
                                            $rootScope.$broadcast('otherShaas');
                                        }
                                    }
                                }
                            }
                        }
                    });
                }
            }
        }
        return true;
    });
});
