angular.module(
    'ShaasApp.controllers.SettingsController',
    [
        'ShaasApp.services.ConnectorService',
        'ShaasApp.services.UtilsService'
    ]
)

.controller( 'SettingsController', [
    'utils',
    'store',
    'config',
    '$translate',
    '$location',
    '$rootScope',
    function (utils, store, config, $translate, $location, $rootScope) {

        var settings = this;
        settings.uuid = $rootScope.uuid();
        $rootScope.registerCloud();
}]);
