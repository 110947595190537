angular.module(
    'ShaasApp.controllers.UsersController',
    [
        'ShaasApp.services.ConnectorService',
        'ShaasApp.services.UtilsService'
    ]
)

.controller( 'UsersController', [
    'CloudConnector',
    'ConnectorNoRest',
    'ConnectorRest',
    'utils',
    'store',
    'config',
    'BootService',
    '$translate',
    '$location',
    '$rootScope',
    '$q',
    '$scope',
    function (CloudConnector, ConnectorNoRest, ConnectorRest, utils, store, config, BootService, $translate, $location, $rootScope, $q, $scope) {

        var users = this;
        users.loginError = false;
        users.loading = false;
        users.doRemote = true;

        $scope.$on('shaas_unreachable', function(response) {
            $location.path("/login");
            users.connectionError = true;
        })

        users.data = {
            'username': config.general.user
        };

        uuid = $rootScope.uuid();

        if(uuid && uuid != "[object Object]") {
            users.name = store.get(uuid).name;
        }

        users.refresh = function() {
            store.remove('remote');
            var promise = BootService.discover();
            promise.then(function(promise) {
                if(uuid && uuid != "[object Object]") {
                   users.name = store.get(uuid).name;
                }
                if (promise == 'ConnectorNoRest') {
                    $rootScope.connector = ConnectorNoRest;
                } else if (promise == 'ConnectorRest') {
                    $rootScope.connector = ConnectorRest;
                }
            });
        }

        users.login = function() {
            connector = $rootScope.connector;
            if (connector == undefined) {
                $rootScope.isDisabled = false;
                return;
            }

            users.loading = true;
            if (
                (!_.has(users.data, 'username') || !_.has(users.data, 'password')) ||
                (_.isUndefined(users.data.username) || _.isUndefined(users.data.password))
            ) {
                $rootScope.isDisabled = false;
                users.loading = false;
                return;
            }

            var router;

            connector.login(users.data).then(function(response) {
                if (response.status != 200) {
                    users.loginError = true;
                } else {
                    if (store.get('router') == "UUID not set") {
                        var promise = BootService.discover();
                        promise.then(function(promise) {
                            store.remove('UUID not set');
                            if (promise == 'ConnectorNoRest') {
                                $rootScope.connector = ConnectorNoRest;
                            } else if (promise == 'ConnectorRest') {
                                $rootScope.connector = ConnectorRest;
                            }
                            users.connect(response);
                        }, function(reason) {
                            users.connectionError = true;
                            users.loading = false;
                            $rootScope.isDisabled = false;
                        });
                    } else {
                        users.connect(response);
                    }
                }
            }, function(error) {
                // Try to login remote.
                if (error.status == -1 && error.statusText == "") {
                    if (users.doRemote) {
                        users.loading = true;
                        users.doRemote = false;

                        var promise = BootService.discover();
                        promise.then(function(promise) {
                            if (promise == 'ConnectorNoRest') {
                                $rootScope.connector = ConnectorNoRest;
                            } else if (promise == 'ConnectorRest') {
                                $rootScope.connector = ConnectorRest;
                            }
                            router = $rootScope.uuid();
                            CloudConnector.check_registration(router).then(function(response) {
                                users.login();
                            }, function(error) {
                                users.connectionError = true;
                                users.loading = false;
                                $rootScope.isDisabled = false;
                            });
                        }, function(reason) {
                            users.connectionError = true;
                            users.loading = false;
                            $rootScope.isDisabled = false;
                        });
                    } else {
                        users.loading = false;
                        users.connectionError = true;
                        $rootScope.isDisabled = false;
                    }
                }

                // Bad Gateway.
                if (error.status == 502) {
                    users.loading = false;
                    users.connectionError = true;
                    $rootScope.isDisabled = false;
                }

                // Bad credentials.
                if (error.status == 401) {
                    users.loading = false;
                    users.loginError = true;
                    $rootScope.isDisabled = false;
                }
            });
        }

        users.logout = function() {
            $rootScope.demo = true;
            users.demo = true;
            uuid = $rootScope.uuid();
            if (store.get(uuid)) {
                router = store.get(uuid);
                router.token = null;
                store.set(uuid, router);
                store.remove('change_router');
                $location.path('/login');
                users.refresh();
            }
        }

        users.connect = function(response) {
            token = response.data.token
            connector.uuid().then(function(response) {
                if (response.status != 200) {
                    users.loading = false;
                } else {
                    $rootScope.demo = false;
                    router = response.data;
                    users.registration(router);
                    $location.path('/');
                }
                $rootScope.isDisabled = false;
            }, function(error) {
                users.loginError = true;
                users.loading = false;
                users.data.username = '';
                users.data.password = '';
                $rootScope.isDisabled = false;
            });
        }

        users.setDemo = function() {

            swal({
                title: $translate.instant('USERS.DEMO_TITLE'),
                text: $translate.instant('USERS.DEMO_INFO'),
                showCancelButton: true,
                confirmButtonText: $translate.instant('USERS.DEMO_ON'),
                cancelButtonText: $translate.instant('USERS.DEMO_OFF')
            }, function() {

                users.loading = true;

                users.data = {
                    'username': config.demo.username,
                    'password': config.demo.password
                }

                var promise = BootService.discover_remote(config.demo.url);
                promise.then(function(promise) {
                    if (promise == 'ConnectorNoRest') {
                        $rootScope.connector = ConnectorNoRest;
                    } else if (promise == 'ConnectorRest') {
                        $rootScope.connector = ConnectorRest;
                    }
                    $rootScope.activateDemo = true;
                    users.login();
                });
            });
        }

        users.setApiRemote = function() {
            connector = $rootScope.connector;
            var url = config.apis.remote.replace("%s", $rootScope.uuid());

            if (url == undefined) {
                return;
            }

            connector.set_base_url(url);
            users.login();
        }

        users.registration = function(router) {
            store.get(router).token = token;
            if (!store.get(router).logged) {
                store.get(router).logged = true;
            }
            store.get(router).deleted = false;
            store.set(router, store.get(router));
            $rootScope.$broadcast('other_shaas_correct');
            CloudConnector.check_registration(router).then(function(response) {
                if ($rootScope.activateDemo != true){
                    users.checkRemote().then(function(response) {
                        // Do nothing
                    }, function(error) {
                        users.setTunnel(router);
                    });
                }
            }, function(error) {
                if (!store.get("router_active")) {
                    if (error.status == 404) {
                        users.setTunnel(router);
                    }
                    if (error.status == 403) {
                        connector.credentials().then(function(response) {
                            data = {
                                "jwt": response.data.jwt_console,
                                "uuid": response.data.uuid,
                                "ssh_pbk": response.data.ssh_pub
                            }
                            token_itcx = config.reseller.token_itcx;
                            CloudConnector.create_controller(token_itcx,data).then(function(response) {
                                if (response.status === 201) {
                                    users.setTunnel(router);
                                    store.set("router_active", 1);
                                }
                            });
                        });
                    }
                    if (error.status == 404) {
                        users.setTunnel(router);
                    }
                } else {
                    swal({
                        title: $translate.instant('USERS.PREMIUM_FAIL'),
                        text: $translate.instant('USERS.PREMIUM_INFO_ACTIVE'),
                    });
                }
            });
        }

        users.setTunnel = function(router) {
            body = {'uuid': router}
            CloudConnector.plugins(config.plugins.tunnel, body).then(function(response) {
                connector.plugins(response.data);
            });
        }

        users.checkRemote = function() {
            var defered = $q.defer();
            var promise = defered.promise;

            var url = config.apis.remote.replace("%s", $rootScope.uuid());
            if (url == undefined) {
                return;
            }

            connector.set_base_url(url);
            connector.uuid().then(function(response) {
                connector.set_base_url(config.apis.cpe);
                if (response.status == 200) {
                    defered.resolve(true);
                }
                defered.reject(false);
            }, function(error) {
                defered.reject(false);
            });
            return promise;
        }
}]);