angular.module('ShaasApp.controllers.ClassesController', [
    'ShaasApp.services.ConnectorService',
    'ShaasApp.services.UtilsService',
    'ShaasApp.filters.GeneralFilter'
])

.controller('ClassesController', [
    'utils',
    '$translate',
    '$route',
    '$window',
    '$routeParams',
    '$rootScope',
    function(utils, $translate, $route, $window, $routeParams, $rootScope) {

        var classes = this;
        var units;
        var total_bw_bits = 0;
        var minimun_sum = 0;

        classes.get_data = function() {
            minimun_sum = 0;
            whoami = 0;
            connector = $rootScope.connector;

            connector.whoami().then(function(response) {
                if (response.status == 500) {
                    swal(
                        $translate.instant('CLASSES.ERROR'),
                        $translate.instant('CLASSES.GENERAL_ERROR'),
                        'error'
                    );
                }

                if (_.has(response.data, 'ip')) {
                    whoami = response.data.ip;
                }

                 // Get all classes
                connector.classes_get_all().then(function(response) {
                    if (response.status == 204) {
                        swal({
                            title: $translate.instant('CLASSES.EMPTY'),
                            text: $translate.instant('CLASSES.EMPTY_MSG'),
                            type: 'info',
                            showCancelButton: true,
                            confirmButtonText: $translate.instant('CLASSES.GO'),
                            cancelButtonText: $translate.instant('CLASSES.NOT_GO')
                        }, function() {
                            $window.location.href = '#/setup';
                        });
                        return;
                    } else if (response.status == 500) {
                        swal(
                            $translate.instant('CLASSES.ERROR'),
                            $translate.instant('CLASSES.GENERAL_ERROR'),
                            'error'
                        );
                   }

                    _data = response.data.classes;
                    if (!_.has(_data, '1_2')) {
                        // swal(
                        //     $translate.instant('HOME.ERROR'),
                        //     $translate.instant('HOME.GENERAL_ERROR'),
                        //     'error'
                        // );
                        return;
                    }

                    parent = _data['1_2'];
                    delete _data['1_2'];
                    total_bw_bits = parent.down.max;

                    // Convert bits into Kb/Mb/Gb
                    conversion_down = utils.bitsToAny(parent.down.max);

                    classes.total_down_bw = conversion_down['bandwidth'];

                    units = conversion_down['units'];

                    _dataSorted = new Object();

                    me = ' (' + $translate.instant('CLASSES.ME') + ')';

                    // Build classes for view
                    _.each(_data, function(value, key) {

                        // minimun_sum += parseInt(value.down.min);

                        utils.conversion(
                            value,
                            utils.bitsToAny,
                            units
                        );

                        bandwidth_down = utils.bitsToAny(value.down.rate);
                        value.bandwidth = {
                            'up': utils.bitsToAny(
                                    value.up.rate,
                                    bandwidth_down.units
                                ).bandwidth,
                            'down': bandwidth_down.bandwidth,
                            'units': bandwidth_down.units
                        };
                        if (value.bandwidth.down == undefined) {
                            value.bandwidth.down = "0.0 kb";
                        }
                        if (value.bandwidth.up == undefined) {
                            value.bandwidth.up = "0.0 kb";
                        }

                        if (value._meta.arch === 'generic') {
                            value._meta.arch = 'desktop';
                        } else if (value._meta.arch === 'guests') {
                            value._meta.arch = 'users';
                            if (value.bandwidth.up < 10 && value.bandwidth.units == "Kb") {
                                value.bandwidth.up = "0.0";
                            }
                            if (value.bandwidth.down < 10 && value.bandwidth.units == "Kb") {
                                value.bandwidth.down = "0.0";
                            }
                        }

                        value._meta.suspended = !value._meta.suspended;
                        value._meta.loading = false;

                        // Assign whoami device to first position.
                        if (_.has(value._meta, 'ip') && value._meta.ip == whoami) {
                            if (value._meta.description.indexOf(me) == -1) {
                                value._meta.description += me;
                            }
                            _dataSorted[key] = value;
                        }
                    });

                    // TODO must do Backend.
                    _.each(_data, function(value, key) {
                        if (_.has(value._meta, 'ip') && value._meta.ip == whoami) {
                            return;
                        }

                        // Apply all devices below whoami device
                        _dataSorted[key] = value;
                    });

                    var lowerCaseSorted = _.sortBy(_dataSorted, function (num) { return num._meta.description.toLowerCase(); });

                    var _dataSortedAlphabetically = new Object();
                    _.each(lowerCaseSorted, function(value, key) {
                        _dataSortedAlphabetically[value.down.class_id] = value;
                    });

                    classes.data = _dataSortedAlphabetically;
                    $rootScope.classesData = classes.data;

                }, function(error) {
                    // swal({
                    //     title: $translate.instant('CLASSES.ERROR'),
                    //     text: $translate.instant('CLASSES.EMPTY_MSG'),
                    //     type: 'info',
                    //     html: true
                    // });
                    return;
                });
            });
        }

        classes.get = function(disabled) {
            connector = $rootScope.connector;

            if ($routeParams.id == undefined) {
                return;
            }

            connector.classes_get('1_2').then(function(root_response) {
                if (root_response.status == 500) {
                    swal(
                        $translate.instant('CLASSES.ERROR'),
                        $translate.instant('CLASSES.GENERAL_ERROR'),
                        'error'
                    );
                    return;
                }

                parent = root_response.data.classes;

                connector.classes_get($routeParams.id).then(function(response) {
                    if (response.status == 500) {
                        swal(
                            $translate.instant('CLASSES.ERROR'),
                            $translate.instant('CLASSES.GENERAL_ERROR'),
                            'error'
                        );
                    } else {

                        classes.device = response.data.classes;

                        classes.device._meta.suspended = !classes.device._meta.suspended;


                        utils.conversion(
                            classes.device,
                            utils.bitsToAny,
                            units
                        );

                        bandwidth_down = utils.bitsToAny(classes.device.down.rate);
                        classes.device.bandwidth = {
                            'up': utils.bitsToAny(
                                    classes.device.up.rate,
                                    bandwidth_down.units
                                ).bandwidth,
                            'down': bandwidth_down.bandwidth,
                            'units': bandwidth_down.units
                        };

                        if (classes.device._meta.arch === 'generic') {
                            classes.device._meta.arch = 'desktop';
                        } else if (classes.device._meta.arch === 'guests') {
                            classes.device._meta.arch = 'users';
                            if (classes.device.bandwidth.up < 10 && classes.device.bandwidth.units == "Kb") {
                                classes.device.bandwidth.up = 0;
                            }
                            if (classes.device.bandwidth.down < 10 && classes.device.bandwidth.units == "Kb") {
                                classes.device.bandwidth.down = 0;
                            }
                        }

                        down_parent = utils.bitsToAny(parent.down.max);
                        total_bw_bits = parent.down.max;

                        if (Number(classes.device.down.max) > Number(down_parent.bandwidth) + 2) {
                            classes.device.down.max = classes.device.down.max / 1000;
                        }

                        classes.device.slider = utils.setPercentage(classes.device.down.min, classes.device.down.max, down_parent.bandwidth);

                        classes.device.slide_disable = false;
                    }
                });
            });
        }


        // Update values to classes
        classes.update = function(device, id, changed_name, changed_slide) {
            connector = $rootScope.connector;

            if ((changed_name == 'undefined' || changed_name == 0) && (changed_slide == 'undefined' || changed_slide == 0)) {
                return;
            }

            if (changed_name == true){
                device._meta.loading_name = true;
            } else if (changed_slide == true){
                device._meta.loading_slide = true;
            }

            total_bandwith = utils.bitsToAny(total_bw_bits);

            // Replace with updated max slider value
            device = utils.setSliderValues(device, classes.device.slider.max, total_bandwith.bandwidth);

            // Convert min and max values unit into bits
            device = utils.conversion(device, utils.anyToBits, total_bandwith.units);

            // Copy object to mantain value of suspended flag.
            deviceToUpdate = angular.copy(device);
            deviceToUpdate._meta.suspended = !deviceToUpdate._meta.suspended;

            connector.classes_update(deviceToUpdate, id).then(function(response) {
                if (response.status = 204) {
                    classes.changed.slide = 0;
                    classes.changed.name = 0;
                    // Convert again to porcentage values
                    device = utils.conversion(device, utils.bitsToAny, units);
                } else {
                    swal(
                        $translate.instant('CLASSES.ERROR'),
                        $translate.instant('CLASSES.MODIFIED_WRONG'),
                        'error'
                    );
                }
                device._meta.loading_slide = false;
                device._meta.loading_name = false;
            });
        };

        classes.suspend = function(device, not_suspended) {
            connector = $rootScope.connector;
            var ip = device.down.dst.split('/')[0];

            if (not_suspended == false) {
                //Suspend
                connector.classes_suspend(ip).then(function(response) {
                    if (response.status != 201) {
                        swal(
                            $translate.instant('CLASSES.ERROR'),
                            $translate.instant('CLASSES.MODIFIED_WRONG'),
                            'error'
                        );
                    }
                });

            } else {
                // Unsuspend
                connector.classes_unsuspend(ip).then(function(response) {
                    if (response.status != 204) {
                        swal(
                            $translate.instant('CLASSES.ERROR'),
                            $translate.instant('CLASSES.MODIFIED_WRONG'),
                            'error'
                        );
                    }
                });
            }
        }

        classes.delete = function(id) {
            connector = $rootScope.connector;
            //Delete
            swal({
                title: $translate.instant('CLASSES.WARNING_DELETE'),
                text: $translate.instant('CLASSES.WARNING_DELETE_INFO'),
                showCancelButton: true,
                confirmButtonText: $translate.instant('CLASSES.YES_DELETE'),
                cancelButtonText: $translate.instant('CLASSES.NO_DELETE')
            }, function() {
                connector.classes_delete(id).then(function(response) {
                    if (response.status != 204) {
                        swal(
                            $translate.instant('CLASSES.ERROR'),
                            $translate.instant('CLASSES.MODIFIED_WRONG'),
                            'error'
                        );
                    } else {
                        swal({
                            title: $translate.instant('CLASSES.DELETE_OK'),
                            type: 'success'
                        }, function() {
                            $window.location.href = '#/';
                        });
                    }
                });
            });
        }
}]);