angular.module(
    'ShaasApp.controllers.ProfilesController',
    [
        'ShaasApp.services.ConnectorService',
        'ShaasApp.services.UtilsService',
        'ngSanitize',
        'ui.select'
    ]
)

.filter('propsFilter', function() {
  return function(items, props) {
    var out = [];

    if (angular.isArray(items)) {
      items.forEach(function(item) {
        var itemMatches = false;

        var keys = Object.keys(props);
        for (var i = 0; i < keys.length; i++) {
          var prop = keys[i];
          var text = props[prop].toLowerCase();
          if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
            itemMatches = true;
            break;
          }
        }

        if (itemMatches) {
          out.push(item);
        }
      });
    } else {
      // Let the output be the input untouched
      out = items;
    }

    return out;
  }
})

.controller( 'ProfilesController', [
    'BootService',
    'CloudConnector',
    'config',
    'utils',
    'store',
    '$translate',
    '$window',
    '$location',
    '$rootScope',
    '$mdSidenav',
    'ConnectorNoRest',
    'ConnectorRest',
    function (BootService, CloudConnector, config, utils, store, $translate, $window, $location, $rootScope, $mdSidenav, ConnectorNoRest, ConnectorRest) {

        var profiles = this;

        profiles.data = {
            'guests': {
                'icon': 'users',
                'i18n': $translate.instant('PROFILES.GUESTS'),
                'loading': false,
                'action': false,
                'info': $translate.instant('PROFILES.GUESTS-INFO')
            },
            'movie': {
                'icon': 'film',
                'i18n': $translate.instant('PROFILES.MOVIE'),
                'loading': false,
                'action': true,
                'info': $translate.instant('PROFILES.MOVIE-INFO')
            },
            // 'me': {
            //     'icon': 'user',
            //     'i18n': $translate.instant('PROFILES.ME'),
            //     'loading': false,
            //     'action': false,
            //     'info': $translate.instant('PROFILES.ME-INFO')
            // },
            'all_me': {
                'icon': 'plus',
                'i18n': $translate.instant('PROFILES.ALL_ME'),
                'loading': false,
                'action': false,
                'info': $translate.instant('PROFILES.ALLME-INFO')
            },
            'equitable': {
                'icon': 'balance-scale',
                'i18n': $translate.instant('PROFILES.EQUITABLE'),
                'loading': false,
                'action': false,
                'info': $translate.instant('PROFILES.EQUITABLE-INFO')
            },
            'adaptative': {
                'icon': 'recycle',
                'i18n': $translate.instant('PROFILES.ADAPTATIVE'),
                'loading': false,
                'action': false,
                'info': $translate.instant('PROFILES.ADAPTATIVE-INFO')
            },
            'all_for': {
                'icon': 'at',
                'i18n': $translate.instant('PROFILES.ALL-FOR'),
                'loading': false,
                'action': true,
                'info': $translate.instant('PROFILES.ALL-FOR-INFO')
            }
        }

        profiles.premium = false;

        profiles.get_data = function() {

            $rootScope.isDisabled = false;

            //Control local or remote
            var promise = BootService.discover();
            promise.then(function(promise) {
                if ($rootScope.uuidRemote()) {
                    if (promise == 'ConnectorNoRest') {
                        $rootScope.connector = ConnectorNoRest;
                    } else if (promise == 'ConnectorRest') {
                        $rootScope.connector = ConnectorRest;
                    }
                }
            });

            connector = $rootScope.connector;
            $rootScope.$watch('uuid', function () {
                uuid = $rootScope.uuid();
                uuidRemote = $rootScope.uuidRemote();
                if (uuidRemote) {
                    uuidNow = uuidRemote;
                } else {
                    uuidNow = uuid;
                }

                profiles.body = {
                    'uuid': uuidNow
                }

                CloudConnector.plugins(config.plugins.get_all).then(function(response) {
                    connector.plugins(response.data.lua_code).then(function(response) {
                        if (response.status != 201) {
                            swal(
                                $translate.instant('CLASSES.ERROR'),
                                $translate.instant('CLASSES.MODIFIED_WRONG'),
                                'error'
                            );
                        } else {
                            _.each(response.data.plugins, function(value, key) {
                                if (profiles.data[key]) {
                                    profiles.data[key]['status'] = (value == "true");
                                }
                            });
                        }
                    }, function(error) {
                        if (error.status == -1){
                            var promise = BootService.discover();
                            promise.then(function(promise) {
                                if (promise == 'ConnectorNoRest') {
                                    $rootScope.connector = ConnectorNoRest;
                                } else if (promise == 'ConnectorRest') {
                                    $rootScope.connector = ConnectorRest;
                                }
                            }, function(reason) {
                            });
                        }
                    });
                }, function(error) {
                    if (error.status == 404 || error.status == 403) {
                        profiles.premium = true;
                    }
                });
            });
        }

        profiles.turn_on = function(type) {
            connector = $rootScope.connector;
            if (profiles.data[type]['status'] !== true) {
                profiles.data[type]['loading'] = true;

                if (profiles.data[type]['action'] == false) {
                    CloudConnector.plugins(config.plugins.turn_on).then(function(response) {
                        params = "params = { action = '" + type + "' , options = { status = 'true' } }\n";
                        b64 = atob(response.data.lua_code);
                        pluginData = btoa(params.concat(b64));

                        connector.plugins(pluginData).then(function(response) {
                            if (response.status != 201) {
                                swal(
                                    $translate.instant('CLASSES.ERROR'),
                                    $translate.instant('CLASSES.MODIFIED_WRONG'),
                                    'error'
                                );
                                profiles.data[type]['loading'] = false;
                                return;
                            }

                            var currentProfileActive;
                            _.find(profiles.data, function(value, key){
                                if(value.status == true) {
                                    currentProfileActive = key;
                                    return true;
                                }
                            });

                            profiles.data[currentProfileActive]['status'] = false;
                            profiles.data[type]['status'] = true;
                            profiles.data[type]['loading'] = false;
                        });
                    }, function(error) {
                        profiles.data[type]['loading'] = false;
                    });
                } else {
                    $location.path('/profiles/' + type);
                }
            }
            return;
        }

        profiles.get_devices_list = function() {

            connector = $rootScope.connector;
            connector.classes_get_all().then(function(response) {
                if (response.status == 500) {
                    return;
                }

                $rootScope.isDisabled = false;

                delete response.data.classes['1_2'];

                profiles.devices = [];
                _.each(response.data.classes, function(value, key) {
                    if (value._meta.arch === 'generic') {
                        value._meta.arch = 'desktop';
                    } else if (value._meta.arch === 'guests') {
                        value._meta.arch = 'users';
                    }

                    profiles.devices.push({
                        'description': value._meta.description,
                        'arch': value._meta.arch,
                        'ip': value._meta.ip,
                        'class_id': key
                    });
                });
            }, function(error) {
                return;
            });
        }

        profiles.set = function(profile, device) {
            connector = $rootScope.connector;
            if (device == undefined) {
                return false;
            }
            profiles.loading = true;

            data = {
                'uuid': $rootScope.uuid()
            }

            CloudConnector.plugins(config.plugins.turn_on).then(function(response) {
                params = "params = { action = '"+ profile +"' , options = { status = 'true', class_id = '"+ device.class_id + "' } }\n";
                b64 = atob(response.data.lua_code);
                pluginData = btoa(params.concat(b64));

                connector.plugins(pluginData).then(function(response) {
                    if (response.status != 201) {
                        swal(
                            $translate.instant('CLASSES.ERROR'),
                            $translate.instant('CLASSES.MODIFIED_WRONG'),
                            'error'
                        );

                        profiles.loading = false;
                        $location.path('/profiles');
                        return;
                    }
                    profiles.loading = false;
                    $location.path('/profiles');
                }, function(error) {
                    profiles.loading = false;
                    return;
                });
            }, function(error) {
                if (error.status == 404) {
                    profiles.loading = false;
                    return;
                }
            });
        }

        profiles.isOpenRight = function(){
            return $mdSidenav('right').isOpen();
        };
        profiles.close = function () {
            $mdSidenav('right').close();
        };
        profiles.toggleRight = function(profile) {
            $mdSidenav('right')
                .toggle()
                .then(function () {
                    profiles.selected = profiles.data[profile];
                });
        };

}]);
