angular.module('ShaasApp.controllers.RemotesController', [
    'ShaasApp.services.ConnectorService'
])

.controller( 'RemotesController', [
    'BootService',
    'ConnectorRest',
    'ConnectorNoRest',
    'config',
    'store',
    '$route',
    '$controller',
    '$interval',
    '$rootScope',
    '$scope',
    '$mdMedia',
    '$mdDialog',
    '$location',
    '$timeout',
    '$translate',
    function (BootService, ConnectorRest, ConnectorNoRest, config, store, $route, $controller, $interval, $rootScope, $scope, $mdMedia, $mdDialog, $location, $timeout, $translate) {

        var remote = this;

        remote.loading = false;
        remote.loginError = false;

        $scope.$on('selectConnect', function(response) {
            remote.selectConnect();
        })

        $scope.$on('otherShaas', function(response) {
            remote.otherShaas();
        })

        $scope.$watch('setWebRemote', function(response) {
            uuidRemote = store.get("remote");
            remote.setApi(uuidRemote);
        })

        remote.setApi = function(remote_uuid) {
            connector = $rootScope.connector;
            if (remote_uuid == undefined)  {
                return;
            }

            remote.loading = true;

            $interval(function () {
                remote.loading = false;
            }, 1000);

            var url = config.apis.remote.replace("%s", remote_uuid);
            if (url == undefined) {
                remote.loading = false;
                return;
            }
            BootService.discover_remote(url).then(function(promise) {
                if (promise == 'ConnectorNoRest') {
                    $rootScope.connector = ConnectorNoRest;
                    url = url + "cgi-bin/luci/shaas-api";
                } else if (promise == 'ConnectorRest') {
                    $rootScope.connector = ConnectorRest;
                    url = url + "0.1";
                }

                if (connector) {
                    connector.set_base_url(url);
                }

                var params = $location.search();

                if (params.uuid != undefined) {
                    if (params.token) {
                        router = store.get(params.uuid);
                        router.token = params.token;
                        store.set(params.uuid, router);
                        $location.path('/');
                    }
                    return;
                }
                $location.path('/login');
                // window.location.reload();
            }, function(error) {
                remote.loginError = true;
            });
        }

        $scope.status = '  ';
        $scope.customFullscreen = $mdMedia('xs') || $mdMedia('sm');

        remote.selectConnect = function(ev) {

            values = new Object();
            keys = Object.keys(localStorage);

            if (keys.length == 0) {
                return;
            }

            i = keys.length;
            while ( i-- ) {
                router = store.get(keys[i]);
                if (router.token != null || router.logged) {
                    values[i] = store.get(keys[i]);
                }
            }

            $rootScope.routers = values;

            var useFullScreen = ($mdMedia('sm') || $mdMedia('xs'))  && $scope.customFullscreen;
            $mdDialog.show({
                controller: DialogSelectConnect,
                templateUrl: 'elements/select_remote.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose:true,
                fullscreen: useFullScreen
            })
            .then(function(answer) {
                $scope.status = 'You said the information was "' + answer + '".';
            }, function() {
                $scope.status = 'You cancelled the dialog.';
            });
            $scope.$watch(function() {
                return $mdMedia('xs') || $mdMedia('sm');
            }, function(wantsFullScreen) {
                $scope.customFullscreen = (wantsFullScreen === true);
            });
        }

        DialogSelectConnect.$inject = ['$scope', '$mdDialog'];
        function DialogSelectConnect($scope, $mdDialog) {
            $scope.routers = $rootScope.routers;
            $scope.hide = function() {
                $mdDialog.hide();
                $timeout(function () {
                    $location.path('/');
                    $route.reload();
                }, 2000);
            };
            $scope.cancel = function() {
                store.remove('change_router');
                if (localStorage.length > 1) {
                    uuid = $rootScope.uuid();
                    router = store.get(uuid);
                    router.deleted = true;
                    store.set(uuid, router);
                }
                $mdDialog.cancel();
            };
            $scope.answer = function(answer) {
                $mdDialog.hide(answer);
            };
        }

        remote.delete_massage = function(uuid) {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('REMOTE.DELETE_TITLE'))
                .textContent($translate.instant('REMOTE.DELETE_INFO'))
                .ariaLabel('Delete Shaas')
                .ok($translate.instant('REMOTE.OK'))
                .cancel($translate.instant('REMOTE.CANCEL'));

            $mdDialog.show(confirm).then(function() {
                store.remove(uuid);
                remote.selectConnect();
            }, function() {
                return;
            });
            return;
        };

        remote.connector = function(uuid) {
            routerLast = store.get('router');

            store.set('remote', uuid);
            store.set('router', uuid);
            store.set('last_router', uuid);

            store.get(uuid).remote = true;
            store.set(uuid, store.get(uuid));

            values = new Object();
            keys = Object.keys(localStorage);
            i = keys.length;
            while ( i-- ) {
                router = store.get(keys[i]);
                if (router.uuid != null && router.uuid != uuid) {
                    store.get(router.uuid).remote = false;
                    store.set(router.uuid, store.get(router.uuid));
                }
            }

            var promise = BootService.discover();
            promise.then(function(promise) {
                if (promise == 'ConnectorNoRest') {
                    $rootScope.connector = ConnectorNoRest;
                } else if (promise == 'ConnectorRest') {
                    $rootScope.connector = ConnectorRest;
                }
            }, function(reason) {
                var url = config.apis.remote.replace("%s", uuid);
                router = $rootScope.uuidRemote();
                if (router != undefined) {
                    if (store.get(router).version == '15') {
                        $rootScope.connector = ConnectorNoRest;
                    } else if (store.get(router).version == '14') {
                        $rootScope.connector = ConnectorRest;
                    }
                }
                connector = $rootScope.connector;
                connector.set_base_url(url);
                $location.path('/');
            });
        }

        remote.otherShaas = function(ev) {
            var useFullScreen = ($mdMedia('sm') || $mdMedia('xs'))  && $scope.customFullscreen;
            store.remove('remote');
            router = store.get('router')

            values = new Object();
            keys = Object.keys(localStorage);
            i = keys.length;
            while ( i-- ) {
                routerList = store.get(keys[i]);
                if (routerList.uuid != null) {
                    store.get(routerList.uuid).deleted = false;
                    store.set(routerList.uuid, store.get(routerList.uuid));
                }
            }
            var promise = BootService.discover();
            promise.then(function(promise) {
                if (promise == 'ConnectorNoRest') {
                    $rootScope.connector = ConnectorNoRest;
                } else if (promise == 'ConnectorRest') {
                    $rootScope.connector = ConnectorRest;
                }
            });

            $mdDialog.show({
                controller: DialogOtherShaas,
                templateUrl: 'elements/other_shaas.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose:true,
                fullscreen: useFullScreen
            })
            .then(function(answer) {
                // When success.
            }, function() {
                // When cancelled dialog.
            });
            $scope.$watch(function() {
                return $mdMedia('xs') || $mdMedia('sm');
            }, function(wantsFullScreen) {
                $scope.customFullscreen = (wantsFullScreen === true);
            });
        }

        DialogOtherShaas.$inject = ['$scope', '$mdDialog'];
        function DialogOtherShaas($scope, $mdDialog) {
            $scope.routers = $rootScope.routers;
            $scope.cancel = function() {
                store.remove('change_router');
                uuid = $rootScope.uuid();
                router = store.get(uuid);
                router.deleted = true;
                store.set(uuid, router);
                uuidLastRouter = $rootScope.uuidLastRouter();
                store.set('router', uuidLastRouter);
                store.set('remote', uuidLastRouter);
                remote.selectConnect();
                $mdDialog.cancel();
            };
            $scope.hide = function() {
                $scope.$on('other_shaas_correct', function(response) {
                    $mdDialog.hide();
                    $location.path('/');
                    $route.reload();
                })
            };
        }
}]);
