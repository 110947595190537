angular.module('ShaasApp.controllers.GuestsController', [])

.controller( 'GuestsController', [
    'BootService',
    function (BootService) {

        var guests = this;

        guests.loading = false;

        guests.get = function() {
            connector = $rootScope.connector;
            connector.guests_get().then(function(response) {
                if (response.status != 200) {
                    guests.status = false;
                }
                guests.status = response.guests;
            });
        }
        guests.get();

        guests.turn = function(status) {
            connector = $rootScope.connector;
            guests.loading = true;

            if (!status) {
                connector.guests_off().then(function(response) {
                    if (response.status != 200) {
                        guests.loading = false;
                    }
                });
                return;
            }

            connector.guests_on().then(function(response) {
                if (response.status != 200) {
                    guests.loading = false;
                }
            });
        }
}]);