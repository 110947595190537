angular.module('ShaasApp.controllers.WebAccessController', [
    'ShaasApp.services.ConnectorService'
])

.controller( 'WebAccessController', [
    'CloudConnector',
    '$rootScope',
    '$translate',
    function (CloudConnector, $rootScope, $translate) {

        var web_access = this;

        web_access.uncompleted = true;
        web_access.activated = false;

        web_access.deactivate = function() {
            if (web_access.activated != false) {
                return;
            }

            web_access.uncompleted = true;
        }

        web_access.activate = function() {
            if (web_access.email == undefined) {
                return;
            }

            if ($rootScope.uuid() == undefined) {
                return;
            }

            web_access.loading = true;

            body = {
                'uuid': $rootScope.uuid(),
                'email': web_access.email
            }

            CloudConnector.associate_email(body).then(function(response) {

                web_access.loading = false;
                $rootScope.isDisabled = false;

                if (response.status != 201) {
                    web_access.reload = true;
                    web_access.uncompleted = true;
                    return;
                }

                web_access.uncompleted = false;
                swal({
                    title: $translate.instant('WEB_ACCESS.MAIL_OK'),
                    type: "success"
                })
            }, function(error) {
                if (error.status = -1) {
                    web_access.timeOut = true;
                    web_access.loading = true;
                    web_access.uncompleted = true;
                    web_access.loading = false;
                    $rootScope.isDisabled = false;
                    return;
                }
            });
        }
}]);