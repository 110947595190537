angular.module(
    'ShaasApp.controllers.SignalController',[]
)
.controller( 'SignalController', [
    'BootService',
    'CloudConnector',
    'config',
    'store',
    '$translate',
    '$mdBottomSheet',
    '$rootScope',
    function (BootService, CloudConnector, config, store, $translate, $mdBottomSheet, $rootScope, ConnectorNoRest) {
        var signal = this;

        signal.signal = function(){
          connector = $rootScope.connector;
          CloudConnector.plugins(config.plugins.signal).then(function(response) {
             params = "params={} params.action= 'status' params.value ='full' \n";
             b64 = atob(response.data.lua_code);
             pluginData = btoa(params.concat(b64));
             connector.plugins(pluginData).then(function(response) {
                var latest= response.data.plugins.length - 1;
                signal.signalsNetwork = []
                signal.devicelist = response.data.plugins[latest]
                signal.guest = []
                _.forEach((response.data.plugins), function(valueSsid, key) {
                  if(key != latest){
                    var signalNetwork={
                      ssid:valueSsid.ssid,
                      devicesArray : []
                    }
                    _.forEach((signal.devicelist), function(value, key) {
                      signal.device = {
                        name: '',
                        signal: '',
                        class:''
                      }
                      if (value.down.class_id != "1_2") {
                        signal_values= valueSsid.assoclist[value._meta.mac];
                        //in the list assoclist are only guest
                        delete valueSsid.assoclist[value._meta.mac]
                        if(typeof signal_values!= 'undefined'){
                          signal.device.signal = signal_values.signal;
                          //values are inverted
                          signal.device.class= key;
                          signal.device.name= value._meta.description;
                          delete signal.devicelist[key]
                          //in the list devices are only disconnected
                          signalNetwork.devicesArray.push(signal.device)
                        }
                      }
                    })
                    var i=0
                    _.forEach((valueSsid.assoclist), function(value, key) {
                          i ++
                          var guest = {
                            name:"Guest "+i+": "+key,
                            signal: value.signal,
                            class:'1_10'
                          }
                          signalNetwork.devicesArray.push(guest)
                    })
                    signal.signalsNetwork.push(signalNetwork)
                  }
                })

                signal.disconnected = []
                signal.ethernet = []
                _.forEach((signal.devicelist), function(value, key) {
                  if(key != '1_10' && key != '1_2'){
                     signal.devicedisc = {
                       name: value._meta.description,
                       class: key
                     }
                     if((value.down.rate != 0 || value.up.rate != 0) && value.down.rate != undefined){
                       signal.ethernet.push(signal.devicedisc)
                     }else{
                       signal.disconnected.push(signal.devicedisc)
                     }
                  }
                })
            });
          }, function(error) {
            if (error.status == 404) {
                return;
            }
          });
        }
        signal.showGridBottomSheet = function() {
          signal.alert = '';
          $mdBottomSheet.show({
            templateUrl: 'elements/reference_signal.html',
            controller: 'SignalController',
          }).then(function(clickedItem) {
          });
        };
}]);
