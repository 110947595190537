angular.module('ShaasApp.controllers.HomeController', [
    'ShaasApp.services.ConnectorService',
    'ShaasApp.services.UtilsService',
    'ShaasApp.filters.GeneralFilter'
])

.controller('HomeController', [
    '$rootScope',
    'store',
    'utils',
    '$translate',
    '$route',
    '$window',
    '$controller',
    '$location',
    'BootService',
    'ConnectorNoRest',
    'ConnectorRest',
    'CloudConnector',
    'config',
    function($rootScope, store, utils, $translate, $route, $window, $controller, $location, BootService, ConnectorNoRest, ConnectorRest, CloudConnector, config) {
        var home = this;

        home.refresh = function() {
            home.status();
            var Classes = $controller('ClassesController'); // passing current scope to commmon controller
            Classes.get_data();
        }
        home.status = function() {
            // Set app is premium or free
            $rootScope.registerCloud();
            //Control local or remote
            var promise = BootService.discover();
            promise.then(function(promise) {
                if ($rootScope.uuidRemote()) {
                    if (promise == 'ConnectorNoRest') {
                        $rootScope.connector = ConnectorNoRest;
                    } else if (promise == 'ConnectorRest') {
                        $rootScope.connector = ConnectorRest;
                    }
                }
            });

            connector = $rootScope.connector;
            home.shaas_state = true;
            connector.status().then(function(response) {
                if (response.data.status.bw_total == 0) {
                    $location.path('/setup');
                }
                if (response.status != 200) {
                    swal(
                    $translate.instant('HOME.ERROR'),
                    $translate.instant('HOME.MODIFIED_WRONG'),
                    'error'
                    );
                }
                data = response.data.status;
                if (data.state == "off"){
                    swal(
                    $translate.instant('HOME.ERROR'),
                    $translate.instant('HOME.GENERAL_ERROR'),
                    'error'
                    );
                }
                $rootScope.data_general = data;
                //home.network = data.essid;
                uuid = $rootScope.uuid();
                uuidRemote = $rootScope.uuidRemote();
                if (!uuidRemote && uuid) {
                  router = store.get(uuid);
                  router.name = home.network;
                  store.set(uuid, router)
                }
                home.signal();
                home.devices = data.devices;
                if (data.state == "on") {
                    home.shaas_state = true;
                    $rootScope.isBlocked = false;
                } else {
                    home.shaas_state = false;
                    $rootScope.isBlocked = true;
                }
                // home.icon_active = data.devices;
                home.state_shaasme = $translate.instant("PROFILES." + data.profile.toUpperCase());
                home.values = utils.bitsToAny(data.bw_total);
                home.units = home.values.units;
                home.bandwidth = Number(home.values.bandwidth);

            }, function(error) {
                if (error.status == -1) {
                    var promise = BootService.discover();
                    promise.then(function(promise) {
                        if (promise == 'ConnectorNoRest') {
                            $rootScope.connector = ConnectorNoRest;
                        } else if (promise == 'ConnectorRest') {
                            $rootScope.connector = ConnectorRest;
                        }
                        if ($rootScope.isPremium()) {
                            $route.reload();
                        } else {
                            swal({
                                title: $translate.instant('GENERAL.ERROR_REMOTE'),
                                text: $translate.instant('GENERAL.ERROR_MGE'),
                            });
                        }
                    }, function(reason) {
                    });
                }
            });
        }
        home.signal = function(){
          connector = $rootScope.connector;
          connector.wifi_get().then(function(response) {
            home.network=[]
            if(response.status == 200){
              if(response.data.wifi.essid != undefined){
                home.network.push(response.data.wifi.essid)
              }else{
             _.forEach((response.data.wifi), function(valueSsid, key) {
                  if(valueSsid != null){
                    home.network.push(valueSsid.ssid)
                    router.name = home.network;
                  }

                });
              }
            }
            if(home.network.length == 0){
               home.network.push($translate.instant('GENERAL.NO_SIGNAL'))
            }
          }, function(error) {
            $rootScope.isBlocked = true;
            return;
          });
        }
        home.turn_on = function(state) {
            connector = $rootScope.connector;
            if(state == false) {
                connector.shaas_off().then(function(response) {
                    if (response.status != 204) {
                        swal(
                        $translate.instant('CLASSES.ERROR'),
                        $translate.instant('CLASSES.MODIFIED_WRONG'),
                        'error'
                        );
                    }
                }, function(error) {
                    $rootScope.isBlocked = false;
                    return;
                });
                return;
            }

            connector.shaas_on().then(function(response) {
                if (response.status != 201) {
                    swal(
                    $translate.instant('CLASSES.ERROR'),
                    $translate.instant('CLASSES.MODIFIED_WRONG'),
                    'error'
                    );
                }
            }, function(error) {
                $rootScope.isBlocked = true;
                return;
            });
        }

    }]);
