angular.module('ShaasApp.controllers.resellers.ResellersLoginController', [
        'ShaasApp.services.ConnectorService',
        'ShaasApp.services.UtilsService'
])

.controller( 'ResellersLoginController', [
    'ConnectorRest',
    'CloudConnector',
    'utils',
    'store',
    'config',
    '$translate',
    '$location',
    '$rootScope',
    function (ConnectorRest, CloudConnector, utils, store, config, $translate, $location, $rootScope) {

        var login = this;
        login.loginError = false;
        login.loading = false;
        login.doRemote = true;

        login.login = function() {
            login.loading = true;
            if (
                (!_.has(login.data, 'email') || !_.has(login.data, 'password')) ||
                (_.isUndefined(login.data.email) || _.isUndefined(login.data.password))
            ) {
                $rootScope.isDisabled = false;
                login.loading = false;
                return;
            }

            var reseller;
            var params = $location.search();

            CloudConnector.resellers_login(login.data).then(function(response) {
                if (response.status != 200) {
                    login.loginError = true;
                } else {
                    reseller = response.data;
                    store.set('reseller', reseller);
                    $location.path('resellers/home');
                }
                $rootScope.isDisabled = false;
            }, function(error) {
                login.loginError = true;
                login.loading = false;
                login.data.email = '';
                login.data.password = '';
                $rootScope.isDisabled = false;
            });
        }

        login.logout = function() {
            reseller = store.get('reseller');
            if (reseller) {
                store.remove('reseller');
                $location.path('/');
            }
        }
}]);